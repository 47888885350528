import React from "react";

import "./assets/fontawesome-free-6.2.1-web/css/fontawesome.css";
// import "./assets/fontawesome-free-6.2.1-web/css/brands.css";
import "./assets/fontawesome-free-6.2.1-web/css/solid.css";
// import "./assets/fontawesome-free-6.2.1-web/css/regular.css";

import Router from "./routes/router";

import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL || "http://127.0.0.1:8082"}/api/`;

// axios.defaults.headers.post["Content-Type"] = "*/*";
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers.post["Accept"] = "*/*";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

axios.defaults.headers["Authorization"] = localStorage.getItem("access_token")
  ? "Bearer " + localStorage.getItem("access_token")
  : "";

export default function App() {
  return <Router />;
}
