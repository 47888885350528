import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faUserPlus, faUser, faCommentDollar } from '@fortawesome/free-solid-svg-icons'
import "./product.css"

function ProductItem(item) {
    const navigate = useNavigate();
    const tensp = "Áo khoác mùa hè"

    return (
        <div className="single-product">
            <div className="product-img">
                {/* <a href="product-detail"> */}
                <Link to='/product-detail'>
                {/* <Link to='/https://zalo.me/0358928123'> */}

                    <img className="default-img" src={item?.item?.hinh} alt="#" />
                    <img className="hover-img" src={item?.item?.hinh} alt="#" />
                    {/* <span className="new">New</span> */}
                    <span className="out-of-stock">Hot</span>
                    {/* <span className="price-dec">30% Off</span> */}
                </Link>
                {/* </a> */}
                <div className="button-head">
                    <div className="product-action">
                        <a data-toggle="modal" data-target="#exampleModal" title="Xem chi tiết" href="#"><i className=" ti-eye"></i><span>Xem chi tiết</span></a>
                        <a title="Yêu thích" href="#"><i className=" ti-heart "></i><span>Thêm vào sẩn phẩm yêu thích</span></a>
                        {/* <a title="Compare" href="#"><i className="ti-bar-chart-alt"></i><span>Add to Compare</span></a> */}
                    </div>
                    <div className="product-action-2">
                        <a title="Liên hệ" href="https://zalo.me/0358928123">  <FontAwesomeIcon icon={faCommentDollar} /> Liên hệ</a>
                    </div>
                </div>
            </div>
            <div className="product-content">
            <Link to='/product-detail'>
               
                    {/* <Link to='/https://zalo.me/0358928123' > */}
                         <h1>{item?.item?.tensp}</h1></Link>
                <div className="product-price">

                    <span>199.000 ₫</span>
                    <span className="old ms-3">499.000 ₫</span>
                </div>
            </div>
        </div>
    )
}

export default ProductItem;

