
import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row, Modal, Container } from 'react-bootstrap';
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
// import emailjs from '@emailjs/browser'
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import { faStar } from '@fortawesome/free-solid-svg-icons';
// import Test from './Test'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import banner1 from '../../../assets/client/images/banner1.png'
import banner from '../assets/client/images/banner1.png'
import SideBar from '../components/frontend/sidebar/SideBar';
function About() {
    const navigate = useNavigate();
    const [show, setshow] = useState(false)
    const [showimg, setshowimg] = useState(false)
    const [key, setKey] = useState(10)
    useEffect(() => {
        document.title = "Giới thiệu";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    return (

        <>
            <div className='detail_bg' id="page_detail">
                <Row className='m-0'>
                    <Col md={{ span: 12, offset: 0}}>
                        <Container className='p-lg-5 bg-white'>

                            <h3 className='fw-bold'>  Kính chào quí khách! </h3>
                            <p style={{ textAlign: 'justify' }}>
                                Ngọc Hằng Store - địa chỉ tin cậy về thời trang Quảng Châu.
                                Có trụ sở chính tại: Đường Nguyễn Huệ, Khóm Bắc Sơn, thị trấn Núi Sập, huyện Thoại Sơn, tỉnh An Giang.
                            </p>

                            <p style={{ textAlign: 'justify' }}>
                                Chào mừng bạn đến với Ngọc Hằng Store, nơi đem đến sự sang trọng và thời trang hàng đầu từ những bộ quần áo hàng Quảng Châu tuyệt đẹp. Với niềm đam mê và kinh nghiệm lâu năm trong ngành thời trang, chúng tôi tự hào là địa chỉ mà bạn có thể hoàn toàn tin tưởng và lựa chọn.
                            </p>

                            <p style={{ textAlign: 'justify' }}>
                                Chúng tôi mang đến một bộ sưu tập đa dạng và phong cách, từ những bộ quần áo trẻ trung, năng động cho đến những thiết kế thướt tha, lịch lãm - phù hợp với mọi dịp và sự kiện trong cuộc sống của bạn. Sử dụng chất liệu cao cấp và đảm bảo mỗi chi tiết được chăm chút tỉ mỉ, sản phẩm tại Ngọc Hằng Store không chỉ đẹp mắt mà còn đảm bảo sự thoải mái khi mặc.
                            </p>

                            <img className='mb-2' src={banner} alt="Hình ảnh Ngọc Hằng Store" width={'100%'} />
                            <h6 className='text-muted text-center'>Hình ảnh Ngọc Hằng Store.</h6>
                            <p style={{ textAlign: 'justify' }}>
                                Với cam kết chất lượng và giá cả hợp lý, chúng tôi luôn đặt lợi ích và sự hài lòng của khách hàng lên hàng đầu. Đội ngũ nhân viên nhiệt tình và chuyên nghiệp sẽ sẵn lòng tư vấn và hỗ trợ bạn trong việc chọn lựa những bộ quần áo phù hợp với phong cách riêng của bạn.
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                Hãy đến với Ngọc Hằng Store để trải nghiệm một không gian mua sắm thú vị và tận hưởng cảm giác tự tin và cuốn hút mỗi khi diện những bộ  quần áo hàng Quảng Châu tinh tế từ chúng tôi.
                            </p>

                            <h3 className='fw-bold'>Cảm ơn!</h3>

                        </Container>
                    </Col>
                </Row>
            </div>

        </>
    )
}

export default About;




