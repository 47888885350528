import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Form, Col, Row, Card } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../datatable/DataTables';
import ProductAddModal from './ProductAddModal';
import moment from 'moment';
import ProductEditModal from './ProductEditModal';
import swal from 'sweetalert';
import CurrencyFormat from 'react-currency-format';

const ProductIndex = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Sản phẩm";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getProducts();
    }, [navigate]);

    const [products, setProducts] = useState([]);
    const [showProductAddModal, setShowProductAddModal] = useState(false);
    const [showProductEditModal, setShowProductEditModal] = useState(false);
    const [idEdit, setIdEdit] = useState('');

    const [selectedRows, setSelectedRows] = useState([]);

    const getProducts = async () => {
        await axios.get(`/product/list/all?limit=${500}&page=${1}`).then((res) => {
            if (res.data.errCode === 0) {
                setProducts(res.data.products);
            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const editTopic = (id) => {
        setShowProductEditModal(true);
        setIdEdit(id);
    }

    const deleteTopic = async (id, name) => {
        await swal({
            title: "Bạn muốn xóa sản phẩm?",
            text: name,
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                await axios.delete(`/product/destroy/${id}`).then((res) => {
                    if (res.data.errCode === 0) {
                        getProducts();
                        toast(res.data.message, { type: 'success' });
                    } else {
                        toast(res.data.message, { type: 'error' });
                    }
                }).catch((error) => {
                    toast(error.message, { type: 'error' });
                });
            }
        })

    }

    const showProductAll = () => {
        const columns = [
            {
                name: "Mã SP",
                selector: (row) => row.code,
                sortable: true,
            },
            {
                name: "Tiêu đề",
                selector: (row) => row.titleVi,
                sortable: true,
            },
            {
                name: "Danh mục",
                selector: (row) => row.categoryNameVi,
                sortable: true,
            },
            {
                name: "Giá bán",
                selector: (row) => row.priceNew > 0 ? row.priceNew : row.price,
                sortable: true,
                right: true,
                cell: row => <>{
                    row.priceNew > 0 ?
                        <div className='d-flex flex-column align-items-end'>
                            <CurrencyFormat
                                value={row.priceNew}
                                displayType={'text'}
                                thousandSeparator={true}
                                renderText={value => <span>{value}</span>}
                            />
                            <CurrencyFormat
                                value={row.price}
                                displayType={'text'}
                                thousandSeparator={true}
                                renderText={value => <del>{value}</del>}
                            />
                        </div >
                        :
                        <CurrencyFormat
                            value={row.price}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={value => <span>{value}</span>}
                        />

                }</>
            },
            {
                name: "Cập nhật",
                selector: (row) => row.updatedAt,
                sortable: true,
                right: true,
                cell: row => <>{moment(row.updatedAt).format('DD/MM/YYYY hh:mm:ss')}</>
            },
            {
                name: "Phương thức",
                center: true,
                cell: row => <>
                    <Button onClick={() => editTopic(row.id)} variant='outline-warning' size="sm" className="me-2" type="button" title='Cập nhật sản phẩm'>
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>

                    <Button onClick={() => deleteTopic(row.id, row.titleVi)} variant='outline-danger' size="sm" type="button" title='Xóa sản phẩm'>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </>
            },
        ];

        const data = [];

        const selectedRow = (items) => {
            setSelectedRows(items);
        };

        if (products.length > 0) {
            products.map((item, i) => {
                data.push({
                    id: item['id'],
                    code: item['code'],
                    categoryId: item['ProductContent.categoryId'],
                    categoryNameVi: item['ProductContent.Category.nameVi'],
                    titleVi: item['ProductContent.titleVi'],
                    price: item['price'],
                    priceNew: item['priceNew'],
                    unitId: item['unitId'],
                    unitName: item['unitName'],
                    updatedAt: item['updatedAt'],
                });
                return item;
            });
        }

        return (<>
            <Card>
                <Card.Header>
                    <div className="d-block d-md-flex justify-content-between">
                        <Card.Title>
                            <i className="fas fa-list me-1" />
                            Danh sách sản phẩm
                        </Card.Title>

                        <div>
                            <Button variant="success" onClick={() => setShowProductAddModal(true)}>
                                <FontAwesomeIcon icon={faPlus} /> Thêm
                            </Button>
                        </div>

                    </div>
                </Card.Header>
                <Card.Body>
                    {
                        <DataTables
                            data={data}
                            columns={columns}
                            selectableRows={true}
                            selectedRows={selectedRow}
                        />
                    }
                </Card.Body>
            </Card>

        </>
        )
    }

    const onSubmitOk = () => {
        setShowProductAddModal(false);
        setShowProductEditModal(false);
        getProducts();
    }

    return (<>
        <div className='container'>
            <Breadcrumb className="p-2  d-flex justify-content-start">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                    Sản phẩm
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Danh sách</Breadcrumb.Item>
            </Breadcrumb>
            {/* <RenderSelectedRows /> */}

            {showProductAll()}

            {showProductAddModal ? <ProductAddModal show={showProductAddModal} onHide={() => setShowProductAddModal(false)} onSubmitSuccess={onSubmitOk} /> : <></>}
            {showProductEditModal && idEdit !== '' ? <ProductEditModal show={showProductEditModal} onHide={() => setShowProductEditModal(false)} idEdit={idEdit} onSubmitSuccess={onSubmitOk} /> : <></>}

        </div>
    </>
    )
}

export default ProductIndex;




