import React from "react";
import { Link, NavLink } from "react-router-dom";
import Check from "../../../components/admin/other/Check";
import './sidebar.css'
// import "../../../assets/admin/css/style.css"
const Sidebar = () => {
  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion" >
      <div className="sb-sidenav-menu">
        <div className="nav">
          <NavLink className="nav-link" to="/admin/">
            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
            Tổng quan
          </NavLink>

          <>
            <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseStudent" aria-expanded="false" aria-controls="pagesCollapseStudent" >
              <div className="sb-nav-link-icon"> <i className="fa-solid fa-file-pen"></i> </div>
              Quản lý bài viết
              <div className="sb-sidenav-collapse-arrow"> <i className="fas fa-angle-down"></i>  </div>
            </Link>
            <div className="collapse" id="pagesCollapseStudent" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion" >
              <nav className="sb-sidenav-menu-nested nav">
                <NavLink className="nav-link" to="/admin/student1">
                  <div className="sb-nav-link-icon"><i className="fa-solid fa-list"></i></div>
                  Bài viết
                </NavLink>
                <NavLink className="nav-link" to="/admin/topic">
                  <div className="sb-nav-link-icon"><i className="fa-solid fa-tag"></i></div>
                  Chủ đề
                </NavLink>
              </nav>
            </div>
          </>
          <>
            <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseStore" aria-expanded="true" aria-controls="pagesCollapseStore" >
              <div className="sb-nav-link-icon"> <i className="fa-solid fa-boxes-stacked"></i> </div>
              Quản lý sản phẩm
              <div className="sb-sidenav-collapse-arrow"> <i className="fas fa-angle-down"></i>  </div>
            </Link>
            <div className="collapse" id="pagesCollapseStore" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion" >
              <nav className="sb-sidenav-menu-nested nav">
                <NavLink className="nav-link" to="/admin/product">
                  <div className="sb-nav-link-icon"> <i className="fa-solid fa-list"></i> </div>
                  Sản phẩm
                </NavLink>

                <NavLink className="nav-link" to="/admin/category">
                  <div className="sb-nav-link-icon"><i className="fa-solid fa-tag"></i></div>
                  Danh mục
                </NavLink>
              </nav>
            </div>
          </>

          <NavLink className="nav-link" to="/admin/user">
            <div className="sb-nav-link-icon"><i className="fa-solid fa-users"></i></div>
            Quản lý nhân viên
          </NavLink>


          <>
            <div className="sb-sidenav-menu-heading">Hệ thống</div>

            <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseSettings" aria-expanded="false" aria-controls="pagesCollapseSettings" >
              <div className="sb-nav-link-icon"> <i className="fa-solid fa-gears"></i> </div>
              Cài đặt
              <div className="sb-sidenav-collapse-arrow"> <i className="fas fa-angle-down"></i>  </div>
            </Link>

            <div className="collapse" id="pagesCollapseSettings" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion" >
              <nav className="sb-sidenav-menu-nested nav">
                <NavLink className="nav-link" to="/admin/unit">
                  <div className="sb-nav-link-icon"> <i className="fa-solid fa-weight"></i> </div>
                  Đơn vị
                </NavLink>
              </nav>
            </div>
          </>

        </div>
      </div>
      <div className="sb-sidenav-footer py-2">
        <div className="small">Version: 1.0</div>
      </div>
    </nav>
  );
};

export default Sidebar;
