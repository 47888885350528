import { Result } from "antd";
import React, { Component } from "react";

class Check extends Component {
    permission(data, type = 'and') {

        if (localStorage.getItem('role_rank') === '1') { return true; }

        const permission = localStorage.getItem('permission') ? localStorage.getItem('permission').split(',') : [];


        if (data.length === 0) {
            return false;
        } else {
            let result = false;

            data.map((item, i) => {
                if (permission.length > 0) {
                    switch(type){
                        case 'or':
                            if (permission.indexOf(item) > -1) { result = true; }
                        break;

                        case 'and':
                            if (permission.indexOf(item) > -1) {
                                result = true;
                            } else {
                                result = false;
                            }
                        break;
                        default: result = false;
                    }
                }
            });

            return result;
        }
    }
}

export default Check;