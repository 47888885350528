import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Form, Col, Row, Card } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../datatable/DataTables';
import UnitAddModal from './UnitAddModal';
import moment from 'moment';
import UnitEditModal from './UnitEditModal';
import swal from 'sweetalert';

const UnitIndex = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Đơn vị";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getUnits();
    }, [navigate]);

    const [units, setunits] = useState([]);
    const [showUnitAddModal, setShowUnitAddModal] = useState(false);
    const [showUnitEditModal, setShowUnitEditModal] = useState(false);
    const [idEdit, setIdEdit] = useState('');

    // const [selectedRows, setselectedRows] = useState([]);
    // const [alertShow, setalertShow] = useState(false);

    const getUnits = async () => {
        await axios.get(`/unit/list/?type=PRODUCT&status=all`).then((res) => {
            if (res.data.errCode === 0) {
                setunits(res.data.units);
            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const editUnit = (id) => {
        setShowUnitEditModal(true);
        setIdEdit(id);
    }

    const deleteUnit = async (id, name) => {
        await swal({
            title: "Bạn muốn xóa đơn vị?",
            text: name,
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                await axios.delete(`/unit/destroy/${id}`).then((res) => {
                    if (res.data.errCode === 0) {
                        getUnits();
                        toast(res.data.message, { type: 'success' });
                    } else {
                        toast(res.data.message, { type: 'error' });
                    }
                }).catch((error) => {
                    toast(error.message, { type: 'error' });
                });
            }
        })

    }

    const showunitAll = () => {
        const columns = [
            {
                name: "Ký hiệu",
                selector: (row) => row.symbol,
                sortable: true,
            },
            {
                name: "Cập nhật",
                selector: (row) => row.updatedAt,
                sortable: true,
                right: true,
                cell: row => <>{moment(row.updatedAt).format('DD/MM/YYYY hh:mm:ss')}</>
            },
            {
                name: "Phương thức",
                center: true,
                cell: row => <>
                    <Button onClick={() => editUnit(row.id)} variant='outline-warning' size="sm" className="me-2" type="button" title='Cập nhật đơn vị'>
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>

                    <Button onClick={() => deleteUnit(row.id, row.symbol)} variant='outline-danger' size="sm" type="button" title='Xóa đơn vị'>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </>
            },
        ];

        const data = units;

        return (
            <Col md={6}>
                <Card>
                    <Card.Header>
                        <div className="d-block d-md-flex justify-content-between">
                            <Card.Title>
                                <i className="fas fa-list me-1" />
                                Danh sách đơn vị
                            </Card.Title>

                            <div>
                                <Button variant="success" onClick={() => setShowUnitAddModal(true)}>
                                    <FontAwesomeIcon icon={faPlus} /> Thêm
                                </Button>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <DataTables data={data} columns={columns} />
                    </Card.Body>
                </Card>
            </Col>
        )
    }

    const onSubmitOk = () => {
        setShowUnitAddModal(false);
        setShowUnitEditModal(false);
        getUnits();
    }

    return (<>
        <div className='container'>
            <Breadcrumb className="p-2  d-flex justify-content-start">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                    Tổng quan
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Đơn vị</Breadcrumb.Item>
            </Breadcrumb>
            {/* <RenderSelectedRows /> */}

            {showunitAll()}

            {showUnitAddModal ? <UnitAddModal show={showUnitAddModal} onHide={() => setShowUnitAddModal(false)} onSubmitSuccess={onSubmitOk} /> : <></>}
            {showUnitEditModal && idEdit !== '' ? <UnitEditModal show={showUnitEditModal} onHide={() => setShowUnitEditModal(false)} idEdit={idEdit} onSubmitSuccess={onSubmitOk} /> : <></>}

        </div>
    </>
    )
}

export default UnitIndex;




