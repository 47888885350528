import { BrowserRouter, Routes, Route } from "react-router-dom";

import "sweetalert/dist/sweetalert.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MasterLayout from "../layouts/admin/masterLayout/MasterLayout";
import MasterLayout_Client from "../layouts/frontend/masterLayoutClient/MasterLayout_Client";
import Home from "../components/frontend/home/Home";
import Login from "../components/frontend/auth/Login";
import Register from "../components/frontend/auth/Register";
import Dashboard from "../components/admin/Dashboard";
import Error404 from "../components/admin/errors/Error404";
import ErrorHome404 from "../components/admin/errors/ErrorHome404";
import Check from "../components/admin/other/Check";
import UserIndex from "../components/admin/user/UserIndex";
// import Test from '../components/frontend/Test'
import Product from "../page/Product"
// import Navbar from "../layouts/frontend/NavBar_Product";
import HomePage from "../components/frontend/home_page/Home_page";
import Cart from "../components/frontend/cart/Cart";
import About from "../page/About";
import Test from '../layouts/frontend/Test'
import ProductDetail from "../components/frontend/product/ProductDetail"
import CategoryIndex from "../components/admin/category/Category";
import TopicIndex from "../components/admin/topic/TopicIndex";
import ProductIndex from "../components/admin/product/ProductIndex";
import UnitIndex from "../components/admin/unit/UnitIndex";
export default function router() {
  return (
    <>
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="/product" element={<Product />} />
            {/* <Route path="/cart" element={<Cart/>}/> */}
            {/* <Route path="test" element={<Test />} /> */}
            <Route path="/login" element={<Login />} />
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path="/about" element={<About />} />
            <Route path="/product-detail" element={<ProductDetail />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="*" element={<ErrorHome404 />} />

          </Route>
          {/* <Route path="/katec/create-school" element={<CreateSchool />} /> */}
          <Route path="admin" element={<MasterLayout />}>
            <Route path="*" element={<Error404 />} />
            <Route path="/admin/user" element={<UserIndex />} />

            <Route path="/admin/category" element={<CategoryIndex />} />
            <Route path="/admin/topic" element={<TopicIndex />} />
            <Route path="/admin/product" element={<ProductIndex />} />
            <Route path="/admin/unit" element={<UnitIndex />} />
          </Route>

          <Route path="*" element={<ErrorHome404 />} />
          {/* <Route path="/home/product" element={<Product/>}/> */}
          <Route path="/tests" element={<Test />} />
          <Route index element={<HomePage />} />
        </Routes>

      </BrowserRouter>
    </>
  );
}
