import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons'
import "./product.css"
function ProductItemNew() {
    const navigate = useNavigate();
    return (
        <div className="single-post first">
            <div className="image">
                <img src="https://th.bing.com/th/id/R.9aa7eaf265881e8bf46b346f8262fffd?rik=QeiG0ZikCfaTuQ&riu=http%3a%2f%2faotrungnien.com%2fuploads%2fao-trung-nien-han-quoc-11.jpg&ehk=IgY8P%2brQaHwsSQ6Gs%2b0lGKmV%2b7sgc53FSN%2b5pKFeAv0%3d&risl=&pid=ImgRaw&r=0" alt="#" />
            </div>
            <div className="content">
                <h5><Link to={"/"}>Áo cho mẹ</Link></h5>
                <p className="price">300.000 ₫</p>
                <p><i className="ti-star " />
                    <i className="ti-star" /> <i className="ti-star" /> <i className="ti-star" />
                    <i className="ti-star" />
                </p>
            </div>
        </div>
    )
}

export default ProductItemNew;

