
import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row, Modal, Container } from 'react-bootstrap';
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
// import emailjs from '@emailjs/browser'
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Test from '../Test'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import ShowImg from './Showimage';
import Footer from '../../../layouts/frontend/footer/Footer';
import MasterLayout_Client from '../../../layouts/frontend/masterLayoutClient/MasterLayout_Client';
import NavbarMiddle from '../../../layouts/frontend/navbarMiddle/Navbar_middle';
// import '../../assets/client/css/responsive.css'
// import '../../assets/client/css/reset.css'
// import '../../assets/client/css/style.css'
// import Navbar from '../../layouts/frontend/NavBar_Product';
import Navbar from '../../../layouts/frontend/navbarN/Navbar';
import './home.css'
function HomeIndex() {
    const navigate = useNavigate();
    const [show, setshow] = useState(false)
    const [showimg, setshowimg] = useState(false)
    const [key, setKey] = useState(10)
    useEffect(() => {
        document.title = "Ngọc Hằng Store";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);



    const formclose = () => {
        setshow(false)
    };
    const formimgclose = () => {
        setshowimg(false)
    };
    const form = useRef()
    const formSDT = useRef()
    const sendSDT = (e) => {
        console.log(formSDT.current.phone.value.length > 9)
        if (formSDT.current.phone.value.length > 9) {
            //   emailjs.sendForm('service_fihg5vx', 'template_es4vjjf', formSDT.current, 'tt8EBINZkDg6uPMLi')
            //     .then(() => {
            //       toast(`Đăng ký thành công`, { type: "success", autoClose: 1000, style: { marginTop: '50px' } });
            //       formSDT.current.phone.value = ''

            //     }, (error) => { console.log(error.text) })
        }
        else {
            toast(`Vui lòng nhập số điện thoại`, { type: "error", autoClose: 1000, style: { marginTop: '50px' } });
        }
    }

    const sendEmail = (e) => {
        if (form.current.phone.value.toString().length > 9) {
            //   emailjs.sendForm('service_fihg5vx', 'template_es4vjjf', form.current, 'tt8EBINZkDg6uPMLi')
            //     .then(() => {
            //       navigate("/dang-ky-thanh-cong")
            //     }, (error) => { console.log(error.text) })
        }
        else {
            toast(`Vui lòng nhập số điện thoại`, { type: "error", autoClose: 1000, style: { marginTop: '50px' } });
        }
        // e.target.reset()
    }


    return (

        <>
            <NavbarMiddle />
            <Navbar />

            <main>

                <Container>
                    <Outlet />
                </Container>
            </main>

            <Footer />
        </>
    )
}

export default HomeIndex;




