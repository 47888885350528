import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UnitEditModal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        getUnit();
    }, [navigate]);

    const [unit, setUnit] = useState({
        id: '',
        type: 'PRODUCT',
        symbol: '',
        nameVi: '',
        nameEn: '',
        statusId: 'S1',
        deleteId: 'D1',
        errors: [],
    });

    const id = props.idEdit || '';

    const [isLoading, setIsLoading] = useState(false);


    const getUnit = async () => {
        if (id !== '') {
            setIsLoading(true);
            await axios.get(`/unit/${id}`).then((res) => {
                console.log(res);
                if (res.data.errCode === 0) {
                    let dataUnit = res.data.unit;

                    const data = {
                        id: dataUnit.id,
                        type: dataUnit.type,
                        symbol: dataUnit.symbol,
                        nameVi: dataUnit.nameVi,
                        nameEn: dataUnit.nameEn,
                        statusId: dataUnit.statusId,
                        deleteId: dataUnit.deleteId,
                        errors: [],
                    };

                    setUnit(data);
                    setIsLoading(false);
                } else {
                    toast(res.data.message, { type: 'error' });
                    setIsLoading(false);
                }
            }).catch((error) => {
                toast(error.message, { type: 'error' });
                setIsLoading(false);
            });
        }
    }

    const handleClose = () => props.onHide();

    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        unit[field] = value;
        delete (unit.errors[field]);

        setUnit({ ...unit });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        await swal({
            title: "Bạn muốn cập nhật đơn vị?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then(async (ok) => {
            if (ok) {
                const data = {
                    symbol: unit.symbol,
                };

                await axios.put(`/unit/update/${unit.id}`, data).then((res) => {
                    if (res.data.errCode === 1) {
                        let unitData = { ...unit };
                        unitData.errors = res.data.errMessages;
                        setUnit(unitData);
                        toast('Cập nhật thất bại.', { type: 'error' });
                    } else {
                        const { onSubmitSuccess } = { ...props };
                        onSubmitSuccess();
                        toast(res.data.message, { type: 'success' });
                    }
                }).catch((error) => {
                    toast(error.message, { type: 'error' });
                })
            }
        })
    }

    return (
        <>
            <Modal size='sm' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title><FontAwesomeIcon icon={faPlus} /> Thêm đơn vị</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInput(e)} name="symbol" defaultValue={unit.symbol} className={unit.errors.symbol ? 'is-invalid' : ''} placeholder="Ký hiệu" />
                            <Form.Label>Ký hiệu<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{unit.errors.symbol ? unit.errors.symbol : ''}</span>
                        </Form.Floating>

                        {/* <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInput(e)} name="nameVi" defaultValue={unit.nameVi} className={unit.errors.nameVi ? 'is-invalid' : ''} placeholder="Tên đơn vị" />
                            <Form.Label>Tên đơn vị</Form.Label>
                            <span className='text-danger'>{unit.errors.nameVi ? unit.errors.nameVi : ''}</span>
                        </Form.Floating> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            <FontAwesomeIcon icon={faTimes} /> Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            <FontAwesomeIcon icon={faCheck} /> Lưu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default UnitEditModal;