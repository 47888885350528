import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import FormatData from '../../other/FormatData';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import TextEditor from './TextEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

const UnitAddModal = (props) => {
    const [unit, setunit] = useState({
        type: 'PRODUCT',
        symbol: '',
        nameVi: '',
        nameEn: '',
        statusId: 'S1',
        deleteId: 'D1',
        errors: [],
    });

    const handleClose = () => props.onHide();

    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        unit[field] = value;
        delete (unit.errors[field]);

        setunit({ ...unit });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            type: unit.type,
            symbol: unit.symbol,
            nameVi: unit.nameVi,
            nameEn: unit.nameEn,
            statusId: unit.statusId,
            deleteId: unit.deleteId,
        };

        await axios.post(`/unit/create`, data).then((res) => {
            if (res.data.errCode === 1) {
                let unitData = { ...unit };
                unitData.errors = res.data.errMessages;
                setunit(unitData);
                toast('Thêm thất bại.', { type: 'error' });
            } else {
                const { onSubmitSuccess } = { ...props };
                onSubmitSuccess();
                toast(res.data.message, { type: 'success' });
            }
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }

    return (
        <>
            <Modal size='sm' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title><FontAwesomeIcon icon={faPlus} /> Thêm đơn vị</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInput(e)} name="symbol" defaultValue={unit.symbol} className={unit.errors.symbol ? 'is-invalid' : ''} placeholder="Ký hiệu" />
                            <Form.Label>Ký hiệu<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{unit.errors.symbol ? unit.errors.symbol : ''}</span>
                        </Form.Floating>

                        {/* <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInput(e)} name="nameVi" defaultValue={unit.nameVi} className={unit.errors.nameVi ? 'is-invalid' : ''} placeholder="Tên đơn vị" />
                            <Form.Label>Tên đơn vị</Form.Label>
                            <span className='text-danger'>{unit.errors.nameVi ? unit.errors.nameVi : ''}</span>
                        </Form.Floating> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            <FontAwesomeIcon icon={faTimes} /> Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            <FontAwesomeIcon icon={faCheck} /> Lưu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default UnitAddModal;