import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import './trendProduct.css'
import "../product/product.css"
import { faCartPlus, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons'
import ProductItem from '../product/ProductItem';
import hinh from '../../../../src/assets/client/images/sanphamdemo.png'
import { Container, NavDropdown, Row, Col, Form, InputGroup, Carousel } from 'react-bootstrap';
function TrendProduct() {
    const navigate = useNavigate();
const product={
    hinh:hinh,
    tensp:"Áo khoác"
}
const data=[
    { id:0,
        hinh:hinh,
        tensp:"Áo khoác 1"
    }, { id:1,
        hinh:'https://scontent.fvca1-1.fna.fbcdn.net/v/t39.30808-6/347840737_815626323246862_7565591448512458040_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=Y2NNZ9cwxTgAX8EHPOB&_nc_ht=scontent.fvca1-1.fna&oh=00_AfB4TqIMCOm56AYkY5M4Q_3fwQ08OcE6QjN5Hb-lj08ryA&oe=64C7E192',
        tensp:"Áo khoác 2"
    }, { id:2,
        hinh:'https://scontent.fvca1-2.fna.fbcdn.net/v/t39.30808-6/347826817_815626249913536_1240833964334172713_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=H_xzgEWFwo0AX8gsQUC&_nc_ht=scontent.fvca1-2.fna&oh=00_AfCuN6GIa4Rk4SqLmwKSi8FMnRqv7NeH-bDKQtjZUk5QrQ&oe=64C8A365',
        tensp:"Áo khoác 3"
    }, { id:3,
        hinh:'https://scontent.fvca1-3.fna.fbcdn.net/v/t39.30808-6/348269666_815626356580192_1922980185424465498_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=GbgdMqRzUuUAX8Uqv73&_nc_ht=scontent.fvca1-3.fna&oh=00_AfDOqVqdrnkcJGy-QjYXKm_SpI6kDQU83ulLgqomWGO5cQ&oe=64C8D398',
        tensp:"Áo khoác 4"
    }
]

    return (
        <div className="product-area section">
            <div >
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Sản phẩm nổi bật</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="product-info">
                            <div className="nav-main">
                                {/* <!-- Tab Nav --> */}
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#man" role="tab">Thời trang trẻ</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#women" role="tab">Thời trang trung niên</a></li>
                                </ul>
                            </div>
                            <Row>
                                {
                                    data.map(item=>{
                                        return(
                                            <Col md={3} sm={6} xs={6} key={`dataProduct${item.id}`}>
                                            <ProductItem  item={item}/>
                                        </Col>
                                        )
                                    })
                                }
                              
                                {/* <Col md={3} sm={6} xs={6}>
                                    <ProductItem />
                                </Col>
                                <Col md={3} sm={6} xs={6}>
                                    <ProductItem />
                                </Col>
                                <Col md={3} sm={6} xs={6}>
                                    <ProductItem />
                                </Col> */}

                            </Row>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrendProduct;

