import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, NavDropdown, Row, Col, Form, InputGroup, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faFacebookF, faInstagramSquare, faTwitterSquare, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import './pageHero.css'
import banner1 from '../../../assets/client/images/banner1.png'
import banner2 from '../../../assets/client/images/banner.PNG'
const PageHero = () => {
    const navigate = useNavigate();

    // const { totalUniqueItems } = useCart();

    const [visibleCategory, setVisibleCategory] = useState(false);

    const { category_slug, search } = useParams();
    const [keyword, setKeyword] = useState(search ? search : '');


    return (
        <section className='page-hero'
        // className="hero-slider"
        >
            <Carousel indicators={false} >

                <Carousel.Item interval={5000}>
                    <img
                        className='banner'
                        // className='carou'
                        src={banner1}
                        alt={'banner'}
                        // height={'500px'}
                        // style={{ objectFit: 'fill', width: '100%' }}
                    />
               
                </Carousel.Item >
                <Carousel.Item interval={5000}>
                    <img
                        // className='carou'
                        src={banner2}
                        className='banner'
                        alt={'banner'}
                        //  height={'500px'}
                        // style={{ objectFit: 'fill', width: '100%' }}
                    />
                </Carousel.Item>
                {/* <Carousel.Item interval={5000}>
            
                <div className="single-slider2">
                        <div className="container">
                            <div className="row no-gutters">
                                <div className="col-lg-9 offset-lg-3 col-12">
                                    <div className="text-inner">
                                        <div className="row">
                                            <div className="col-lg-7 col-12">

                                                <div className="hero-text">
                                                    <h1><span>Ưu đãi vàng </span>Banner</h1>
                                                    <p>Maboriosam in a nesciung eget magnae <br/> dapibus disting tloctio in the find it pereri <br/> odiy maboriosm.</p>
                                                    <div className="button">
                                                        <a href="#" className="btn">Đăng ký thành viên</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </Carousel.Item> */}
            </Carousel>

        </section>
    )
}

export default PageHero;

