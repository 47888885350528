import React,{useEffect} from 'react';
import './homePage.css'
import { Link, useNavigate } from 'react-router-dom';

// import '../../assets/client/css/responsive.css'
// import '../../assets/client/css/reset.css'
// import '../../assets/client/css/style.css'
// import Logo from '../../assets/client/images/logo.png'
// import { faCartPlus, faUserPlus, faUser, faCar } from '@fortawesome/free-solid-svg-icons'
import HotItem from '../hotItem/HotItem';
import Sale from '../sale/Sale';
import TrendProduct from '../trendProduct/TrendProduct';
import Feedback from '../feedback/Feedback';
import { Button, Col, Form, Row, Modal, Container } from 'react-bootstrap';
import Navbar_N from '../../../layouts/frontend/navbarN/NavBar_N';
import Navbar from '../../../layouts/frontend/navbarN/Navbar';

import NavbarMiddle from '../../../layouts/frontend/navbarMiddle/Navbar_middle';

import Footer from '../../../layouts/frontend/footer/Footer';
import PageHero from '../../../layouts/frontend/pageHero/PageHero';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faHandsHoldingCircle, faShieldVirus, faHeadset } from '@fortawesome/free-solid-svg-icons';
import hinh1 from '../../../assets/client/images/banner_demo.png'
function HomePage() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title="Ngọc Hằng Store"
    },[])
    const dataService = [
        {
            icon: faCar,
            name: "MIỄN PHÍ VẬN CHUYỂN",
            content: 'Đơn hàng trên 99.000'

        },
        {
            icon: faHandsHoldingCircle,
            name: "GIÁ TỐT NHẤT",
            content: '100% bảo mật'

        },
        {
            icon: faShieldVirus,
            name: "BẢO HÀNH VÀ ĐỔI TRẢ",
            content: 'Bảo hành trọn đời'

        }, {
            icon: faHeadset,
            name: "TƯ VẤN VÀ HỖ TRỢ",
            content: 'Hỗ trợ 24/7'

        }
    ]
    return (
        <>
            <NavbarMiddle />
            {/* <Navbar_N/> */}
            <Navbar/>
            <Container>
                <PageHero />
                <section className="small-banner section">

                    <div >
                        <div className="row">
                        
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="single-banner">
                                    <img src={hinh1} alt="#" />
                                    <div className="content">
                                        <p>Trọn bộ sưu tập </p>
                                        <h3>Thời trang toàn diện <br />Cho gia đình của bạn</h3>
                                        <Link to={"/product"} >Mua sắm ngay</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="single-banner">
                                    <img src={hinh1} alt="#" />
                                    <div className="content">
                                    <p>Dành cho khách hàng thành viên</p>
                                        <h3>Sale đến cuối tháng<br />Lên đến <span>40%</span></h3>
                                        <Link to={"https://zalo.me/0358928123"} >Đăng ký ngay </Link>
                                      
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <TrendProduct />

                {/* <HotItem />

                <Sale /> */}
                {/* <Feedback /> */}
            </Container>
            <section className="shop-services section home">
                <div className="container">
                    <div className="row py-3">
                        {dataService?.map(service => {
                            return (
                                <div className="col-lg-3 col-md-6 col-6" key={`dataService` + service.content}>
                                    <div className="single-service">
                                        <div style={{ fontSize: '35px', marginRight: '10px', color: '#ffbf00' }}>
                                            <FontAwesomeIcon icon={service.icon} />
                                        </div>
                                        <div>
                                            <h4>{service.name}</h4>
                                            <p>{service.content}</p>
                                        </div>


                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default HomePage;

