import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import FormatData from '../../other/FormatData';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import TextEditor from './TextEditor';

const TopicAddModal = (props) => {
    const [topic, setTopic] = useState({
        slug: '',
        nameVi: '',
        contentVi: '',
        errors: [],
    });

    const [contentVi, setContentVi] = useState('');

    const handleClose = () => props.onHide();
    const convertToSlug_ = (input) => {
        // Xóa các ký tự đặc biệt, chuyển chữ hoa thành chữ thường và thay thế dấu cách bằng dấu gạch ngang
        return input
            .toLowerCase()
            .replace(/[^\w\s]/g, '') // Xóa các ký tự đặc biệt
            .replace(/\s+/g, '-')    // Thay thế dấu cách bằng dấu gạch ngang
            .trim();                 // Xóa các khoảng trắng ở đầu và cuối chuỗi
    }
    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        topic[field] = value;
        delete (topic.errors[field]);

        if (field === 'nameVi') {
            let convertToSlug = convertToSlug_(value);

            delete (topic.errors.slug);
            topic.slug = convertToSlug;
        }

        setTopic({ ...topic });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            slug: topic.slug,
            nameVi: topic.nameVi,
            contentVi: contentVi,
        };

        await axios.post(`/topic/create`, data).then((res) => {
            if (res.data.errCode === 1) {
                let topicData = { ...topic };
                topicData.errors = res.data.messages;
                setTopic(topicData);

                toast('Thêm chủ đề thất bại.', { type: 'error' });
            } else {

                const { onSubmitSuccess } = { ...props };
                onSubmitSuccess();

                toast(res.data.message, { type: 'success' });
            }
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }

    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Thêm chủ đề</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInput(e)} name="nameVi" value={topic.nameVi} className={topic.errors.nameVi ? 'is-invalid' : ''} placeholder="Tên chủ đề" required />
                            <Form.Label>Tên chủ đề<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{topic.errors.nameVi ? topic.errors.nameVi : ''}</span>
                        </Form.Floating>
{/* 
                        <Form.Floating className="mb-3">
                            <Form.Control type="text" name="slug" value={topic.slug} className={topic.errors.slug ? 'is-invalid' : ''} placeholder="Slug" readOnly />
                            <Form.Label>Slug<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{topic.errors.slug ? topic.errors.slug : ''}</span>
                        </Form.Floating> */}

                        {/* <Form.Label>Mô tả</Form.Label>

                        <TextEditor setContents={contentVi} onChange={(value) => setContentVi(value)} placeholder="Mô tả" /> */}

                        <span className='text-danger'>{topic.errors.contentVi ? topic.errors.contentVi : ''}</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Lưu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default TopicAddModal;