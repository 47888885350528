import React,{useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Logo from '../../assets/client/images/logo.png'
import './sidebar.css'
import ProductItem from "../product/ProductItem"
import { Row,Col } from 'react-bootstrap';
import { faCartPlus, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons'
import ProductItemNew from '../product/productItemNew';
import { DisabledContextProvider } from 'antd/es/config-provider/DisabledContext';
function SideBar() {
    const navigate = useNavigate();
const [price,setPrice]= useState(10)
const data=[
    { id:0,
        hinh:'https://scontent.fvca1-4.fna.fbcdn.net/v/t39.30808-6/347658298_815626416580186_3341328744207698278_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=UynmCcu7vq8AX_rpps0&_nc_ht=scontent.fvca1-4.fna&oh=00_AfCaI_7P4O7_MRF0fogzfVtQNANftRO9f2cybogpkXELRQ&oe=64C7B3AB',
        tensp:"Áo khoác 1"
    }, { id:1,
        hinh:'https://scontent.fvca1-1.fna.fbcdn.net/v/t39.30808-6/347840737_815626323246862_7565591448512458040_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=Y2NNZ9cwxTgAX8EHPOB&_nc_ht=scontent.fvca1-1.fna&oh=00_AfB4TqIMCOm56AYkY5M4Q_3fwQ08OcE6QjN5Hb-lj08ryA&oe=64C7E192',
        tensp:"Áo khoác 2"
    }, { id:2,
        hinh:'https://scontent.fvca1-2.fna.fbcdn.net/v/t39.30808-6/347826817_815626249913536_1240833964334172713_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=H_xzgEWFwo0AX8gsQUC&_nc_ht=scontent.fvca1-2.fna&oh=00_AfCuN6GIa4Rk4SqLmwKSi8FMnRqv7NeH-bDKQtjZUk5QrQ&oe=64C8A365',
        tensp:"Áo khoác 3"
    }, { id:3,
        hinh:'https://scontent.fvca1-3.fna.fbcdn.net/v/t39.30808-6/348269666_815626356580192_1922980185424465498_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=GbgdMqRzUuUAX8Uqv73&_nc_ht=scontent.fvca1-3.fna&oh=00_AfDOqVqdrnkcJGy-QjYXKm_SpI6kDQU83ulLgqomWGO5cQ&oe=64C8D398',
        tensp:"Áo khoác 4"
    },
    { id:4,
        hinh:'https://scontent.fvca1-2.fna.fbcdn.net/v/t39.30808-6/347826817_815626249913536_1240833964334172713_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=H_xzgEWFwo0AX8gsQUC&_nc_ht=scontent.fvca1-2.fna&oh=00_AfCuN6GIa4Rk4SqLmwKSi8FMnRqv7NeH-bDKQtjZUk5QrQ&oe=64C8A365',
        tensp:"Áo khoác 3"
    }, { id:5,
        hinh:'https://scontent.fvca1-3.fna.fbcdn.net/v/t39.30808-6/348269666_815626356580192_1922980185424465498_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=GbgdMqRzUuUAX8Uqv73&_nc_ht=scontent.fvca1-3.fna&oh=00_AfDOqVqdrnkcJGy-QjYXKm_SpI6kDQU83ulLgqomWGO5cQ&oe=64C8D398',
        tensp:"Áo khoác 4"
    }
]

    return (
        <section className="product-area shop-sidebar shop section">
            <div className="container mb-3">
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-12">
                        <div className="shop-sidebar ">
                            {/* <!-- Single Widget --> */}
                            <div className="single-widget category">
                                <h3 className="title">Danh mục</h3>
                                <ul className="categor-list">

                                    <li><Link to={"/"}>Thời trang hàng ngày</Link></li>
                                    <li><Link to={"/"}>Thời trang dự tiệc</Link></li>
                                </ul>
                            </div>
                            {/* <!--/ End Single Widget --> */}
                            {/* <!-- Shop By Price --> */}
                            <div className="single-widget range  ">
                                <h3 className="title">Giá sản phẩm</h3>
                           
                                <div className="price-filter">
                                    <div className="price-filter-inner">
                                        {/* <div id="slider-range"></div> */}
                                        <input type="range" value={price} min="0" max="100" onChange={(e)=>setPrice(e.target.value)} id="slider-range" style={{backgroundColor:"red"}}/>
                                        <div className="price_slider_amount">
                                            <div className="label-input">
                                                <span>Khoảng:</span><input type="text" id="amount" name="price" placeholder="Nhập giá" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="check-box-list">
                                    <li>
                                        <label className="checkbox-inline" htmlFor="1"><input name="news" id="1" type="checkbox" /> {"< 500.000 ₫"}<span className="count">(3)</span></label>
                                    </li>
                                    <li>
                                        <label className="checkbox-inline" htmlFor="2"><input name="news" id="2" type="checkbox" />500.000 ₫ - 1.000.000 ₫<span className="count">(5)</span></label>
                                    </li>
                                    <li>
                                        <label className="checkbox-inline" htmlFor="3"><input name="news" id="3" type="checkbox" />{'1.000.000 ₫'}<span className="count">(8)</span></label>
                                    </li>
                                </div>
                            </div>
                            {/* <!--/ End Shop By Price --> */}
                            {/* <!-- Single Widget --> */}
                            <div className="single-widget recent-post">
                                <h3 className="title">Sản Phẩm mới về</h3>
                             
                             <ProductItemNew/>
                             <ProductItemNew/>
                             <ProductItemNew/>
                            </div>
                         
                            {/* <!--/ End Single Widget --> */}
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-8 col-12">
                        <div className="row">
                            <div className="col-12">
                                {/* <!-- Shop Top --> */}
                                <div className="shop-top">
                                    <div className="shop-shorter">
                                        <div className="single-shorter">
                                            <label>Hiển thị :</label>
                                            <select>
                                                <option defaultValue="selected">09</option>
                                                <option>15</option>
                                                <option>25</option>
                                                <option>30</option>
                                            </select>
                                        </div>
                                        <div className="single-shorter">
                                            <label>Sắp xếp :</label>
                                            <select>
                                                <option defaultValue="selected">Tên</option>
                                                <option>Giá</option>
                                                <option>Kích thước</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="view-mode">
                                        <li className="active"><Link to={"/"}><i className="fa fa-th-large"></i></Link></li>
                                        <li><Link to={"/"}><i className="fa fa-th-list"></i></Link></li>
                                    </div>
                                </div>
                                {/* <!--/ End Shop Top --> */}
                            </div>
                        </div>
                        <Row>
                        {
                                    data.map(item=>{
                                        return(
                                            <Col md={4} sm={6} xs={6} key={`data${item.id}`}>
                                            <ProductItem  item={item}/>
                                        </Col>
                                        )
                                    })
                                }
                         
                           
                        </Row>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default SideBar;

