import React ,{useState,useEffect}from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./cart.css"
import { Button, Card, Col, FloatingLabel, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { faCartPlus, faUserPlus, faUser, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useCart } from "react-use-cart";
function Cart() {
    const navigate = useNavigate();
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [optionDistricts, setOptionDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [optionWards, setOptionWards] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [isLoadSubmit, setIsLoadSubmit] = useState(false);
    const [isLoadDiscount, setisLoadDiscount] = useState(false);
    const { isEmpty, cartTotal, totalUniqueItems, items, updateItemQuantity, removeItem, emptyCart, metadata, updateCartMetadata } = useCart();

    useEffect(() => {
        document.title = "Giỏ hàng";
     
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const getLocations = () => {
        setIsLoad(true);
        axios.get(`/getLocations`).then((res) => {
            if (res.status === 200) {
                setProvinces(res.data.provinces);
                setDistricts(res.data.districts);
                setWards(res.data.wards);

                let districtShow = res.data.districts.filter(item => item.province_id === parseInt(metadata.province_id));
                setOptionDistricts(districtShow);

                let wardShow = res.data.wards.filter(item => item.district_id === parseInt(metadata.district_id));
                setOptionWards(wardShow);
            }
            setIsLoad(false);
        }).catch((error) => {
            toast(error.message, { type: 'error' });
            setIsLoad(false);
        });
    }

    const handleInput = (e) => {
        let field = e.target.name;
        let value = e.target.value;

        let data = { ...metadata };

        data[field] = value;

        if (value.length) {
            delete (data.error_list[field]);
        }

        if (field === 'province_id') {
            // const provinceData = provinces.filter(item => item.id === parseInt(value));
            // data['feeshipping'] = provinceData[0].freeship;

            let districtShow = districts.filter(item => item.province_id === parseInt(value));

            setOptionDistricts(districtShow);
            setOptionWards([]);
            data.district_id = '';
            data.ward_id = '';
        } else if (field === 'district_id') {
            // const provinceData = provinces.filter(item => item.id === parseInt(metadata.province_id));
            // const districtData = districts.filter(item => item.id === parseInt(value));


            let wardShow = wards.filter(item => item.district_id === parseInt(value) && item.province_id === parseInt(metadata.province_id));
            setOptionWards(wardShow);
            data.ward_id = '';
        } else if (field === 'discount_code') {
            data.discount_type = '';
            data.discount_value = '';
            data.discount_money = 0;
        }

        updateCartMetadata(data);
    }
    return (

        <>
            {/* <div className="breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bread-inner">
                                <ul className="bread-list">
                                    <li><a href="index1.html">Home<i className="ti-arrow-right"></i></a></li>
                                    <li className="active"><a href="blog-single.html">Cart</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- Shopping Cart --> */}
            <div className="shopping-cart section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* <!-- Shopping Summery --> */}
                            <table className="table shopping-summery">
                                <thead>
                                    <tr className="main-hading">
                                        <th>Sản phẩm</th>
                                        <th>Tên</th>
                                        <th className="text-center">Giá</th>
                                        <th className="text-center">Số lượng</th>
                                        <th className="text-center">Tổng</th>
                                        <th className="text-center">Xóa
                                            {/* <i className="ti-trash remove-icon"></i> */}
                                            </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="image" data-title="No"><img src="https://cdn.nhathuoclongchau.com.vn/unsafe/373x0/filters:quality(90)/https://cms-prod.s3-sgn09.fptcloud.com/00033518_vita_gummies_vitamin_d3_1000iu_120v_7608_6226_large_d82136ae38.jpg" alt="#" /></td>
                                        <td className="product-des" data-title="Description">
                                            <p className="product-name"><a href="#">{"Kẹo dẻo Vita Gummies Vitamin D3 Nature's Way hỗ trợ xương khớp (120 viên)"}</a></p>
                                            <p className="product-des">Sản phẩm dùng hàng ngày</p>
                                        </td>
                                        <td className="price" data-title="Price"><span>499.000 ₫ </span></td>
                                        <td className="qty" data-title="Qty">
                                            <div className="input-group">
                                                <div className="button minus">
                                                    <button type="button" className="btn btn-primary btn-number" disabled="disabled" data-type="minus" data-field="quant[1]">
                                                        <i className="ti-minus"></i>
                                                    </button>
                                                </div>
                                                <input type="text" name="quant[1]" className="input-number" data-min="1" data-max="100" value="1" />
                                                <div className="button plus">
                                                    <button type="button" className="btn btn-primary btn-number" data-type="plus" data-field="quant[1]">
                                                        <i className="ti-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <!--/ End Input Order --> */}
                                        </td>
                                        <td className="total-amount" data-title="Total"><span>499.000 ₫</span></td>
                                        <td className="action" data-title="Remove"><a href="#">
                                            <FontAwesomeIcon icon={faTrashCan} style={{color:'red'}}/>
                                            <i className="ti-trash remove-icon"></i>
                                            </a></td>
                                    </tr>
                                
                               
                                </tbody>
                            </table>
                            {/* <!--/ End Shopping Summery --> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {/* <!-- Total Amount --> */}
                            <div className="total-amount">
                                <div className="row">
                                    <div className="col-lg-8 col-md-5 col-12">
                                        <div className="left">
                                            <div className="coupon">
                                                <form action="#" target="_blank">
                                                    <input name="Coupon" placeholder="Mã giảm giá" />
                                                    <button className="btn">Áp dụng</button>
                                                </form>
                                            </div>
                                            <div className="checkbox">
                                                <label className="checkbox-inline" htmlFor="2"><input name="news" id="2" type="checkbox" /> Vận chuyển (30.000 ₫)</label>
                                            </div>

                           
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-7 col-12">
                                        <div className="right">
                                            <ul>
                                                <li>Tổng <span>499.000 ₫</span></li>
                                                <li>Vận chuyển<span>Free</span></li>
                                                <li className="last">Thành tiền<span>499.000 ₫</span></li>
                                            </ul>
                                            <div className="button5">
                                                <a href="#" className="btn">Tiếp tục đặt hàng</a>
                                                <Link to='/'  className="btn">Tiếp tục mua sắm</Link>
                                                {/* <a href="/" className="btn">Tiếp tục mua sắm</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--/ End Total Amount --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--/ End Shopping Cart --> */}

        </>
    )
}

export default Cart;

