import React, { useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faPinterest, faYoutube, faTwitter, faInstagram, faSquareTumblr, faSquareInstagram } from '@fortawesome/free-brands-svg-icons';

import { toast } from 'react-toastify';
// import emailjs from '@emailjs/browser'
import { Link } from 'react-router-dom';
import Logo from '../../../assets/client/images/logo_white.png'
// import logoIcon from '../../../assets/client/images/logo-vplife-icon.png'
import './footer.css'
function Footer() {
    const formSDT1 = useRef()
    const sendSDT = (e) => {
        if (formSDT1.current.phone.value.toString().length > 9) {
            // emailjs.sendForm('service_fihg5vx', 'template_es4vjjf', formSDT1.current, 'tt8EBINZkDg6uPMLi')
            //     .then(() => {

            //         toast(`Đăng ký thành công`, { type: "success", autoClose: 1000, style: { marginTop: '50px' } });
            //         formSDT1.current.phone.value = ''
            //     }, (error) => { console.log(error.text) })
        }
        else {
            toast(`Vui lòng nhập số điện thoại đúng định dạng`, { type: "error", autoClose: 1000, style: { marginTop: '50px' } });
        }
    }
    return (
        <footer>
            <div className="main-footer">

                <div className="container">
                    {/* <Form ref={formSDT} > */}
                    <Row >

                        <div className="row footer-top clearfix">
                        </div>

                        <div className="row footer-link-box clearfix">
                            <Col md={8} sm={6}>
                                <div className="row left-f-box">
                                    <div className="col-sm-6">
                                        <div style={{ display: 'flex' }}>
                                            <Link className="navbar-brand-footer" to="/">

                                                {/* <img src="/assets/img/banner/logo-vplife-trong-suot.png" alt="VPLife_logo"className='logo_footerl' /> */}
                                                <img src={Logo} alt="Logo"
                                                //  className='logo_footer'
                                                />
                                            </Link>
                                            {/* <h2>Ngọc Hằng Store</h2> */}

                                        </div>
                                        <div>
                                            Chuyên bán lẻ hàng may mặc, giày dép, hàng da và giả da trong các cửa hàng chuyên doanh.
                                        </div>
                                        <div className='mt-2'>
                                            Lịch Live : trưa 11h30 - tối 19h
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <h2>Liên hệ với chúng tôi</h2>

                                        <ul>
                                            <li><div to="tel:0919300003"><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp; 0865499963</div></li>

                                            <li>
                                                <div to="#">
                                                    <FontAwesomeIcon icon={faHome} />    Đường Nguyễn Huệ, Khóm Bắc Sơn, thị trấn Núi Sập, huyện Thoại Sơn, tỉnh An Giang.

                                                </div>
                                            </li>

                                        </ul>
                                        <ul style={{ cursor: 'pointer' }}>
                                            {/* <center> */}
                                            <a href="https://www.facebook.com/tranngochangstore" className='text-white'>
                                                <FontAwesomeIcon icon={faFacebook} className='icon-footer' /></a>
                                            <a href="https://zalo.me/0358928123" className='icon-footer text-white'  >
                                                Zalo </a>

                                            {/* <FontAwesomeIcon icon={faInstagram} className='icon-footer' />
                                            <FontAwesomeIcon icon={faTwitter} className='icon-footer' />
                                            <FontAwesomeIcon icon={faYoutube} className='icon-footer' /> */}

                                            {/* </center> */}
                                        </ul>

                                    </div>
                                </div>
                            </Col>

                            <div className="col-md-4 col-sm-6">
                                <div className="row right-f-box">
                                    <h2><FontAwesomeIcon icon={faLocationDot} />  GOOGLE MAP</h2>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3925.995931342915!2d105.26068242421633!3d10.26191066847493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310a09460d8b46e3%3A0x4f7cd880df534663!2zxJDGsOG7nW5nIE5ndXnhu4VuIGh14buHIGtow7NtIGLhuq9jIHPGoW4gdHQgbsO6aSBz4bqtcA!5e0!3m2!1svi!2s!4v1690334497143!5m2!1svi!2s"
                                        width="150" height="200" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {/* </Form> */}

                </div>
                {/* <img src="../../../assets/user/images/product/detail/footer.png" className="icon-small" alt="" /> */}

            </div>



            <div className="copyright">
                <div className="container">
                    <div className="row">
                        <p className='text-center'>
                            <Link to={'https://thietkeweb.katec.vn'} target='_blank'>
                                Design by Katec
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;