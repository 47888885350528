import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Container,
  Form,
  Modal, Col, Row, InputGroup, Table
} from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DataTables from "../datatable/DataTables";
import Select from 'react-select'
import Check from "../other/Check";
import moment from "moment/moment";
import * as XLSX from "xlsx-js-style";

export default function UserIndex() {
  useEffect(() => {
    document.title = "Nhân viên";
    getlistuser()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [show, setShow] = useState(false);
  const [type, settype] = useState("");
  const [listuser, setlistuser] = useState([]);
  const [userSelectedFile, setuserSelectedFile] = useState(null);
  const [isAddStaffListLoading, setisAddStaffListLoading] = useState(null);
  const [listrole, setlistrole] = useState([]);
  const [listEthnic, setlistEthnic] = useState([]);
  const [id_user, setid_user] = useState("");
  const [user, setuser] = useState({});
  const [selectedRows, setselectedRows] = useState([]);
  const [alertShow, setalertShow] = useState(false);
  const [RoleFilter, setRoleFilter] = useState({ value: 0, label: "Tất cả chức vụ" });
  const [classsearch, setclasssearch] = useState([]);
  const [RoleSearch, setRoleSearch] = useState([])
  const [checkload, setCheckload] = useState(true)

  // Fetch API
  const getlistuser = () => {
    axios.get(`/userList/active?limit=${20}&page=${1}`)
      .then((res) => {
        if (res.data.errCode == 0) {
          setlistuser(res.data.users);
          setCheckload(false)
          handleClassbySelectOrSearch(res.data.users, RoleFilter, "")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setShow(false);
    settype("");
    setuser({});
  };

  function activeuser(item, isactive, event) {
    swal({
      title: `${isactive === 0
        ? "Xác nhận kích hoạt nhân viên?"
        : "Bạn muốn xóa nhân viên?"
        }`,
      text: `"${item.fullName}"`,
      icon: `${isactive === 0 ? "success" : "error"}`,
      buttons: ['Đóng', 'Xóa'],
      dangerMode: isactive !== 0 ? true : false,
      successMode: isactive === 0 ? true : false,
    }).then((ok) => {
      if (ok) {
        axios.delete(`/user/destroy/${item.id}`)
          .then((res) => {
            getlistuser();
            toast(`${res.data.data.mess}`, {
              type: "success",
              autoClose: 1000,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  function getdetailuser(id) {
    axios.get(`/user/${id}`)
      .then((res) => {
        if (res.data.errCode == 0) {
          setuser(res.data.user);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Handle Logic
  const selectUserByRole = (value) => {
    setRoleFilter(value);
    setclasssearch("");
    handleClassbySelectOrSearch(listuser, value, "");
  }

  const handleSearchRole = (event) => {
    setclasssearch(event.target.value)

    handleClassbySelectOrSearch(listuser, RoleFilter, event.target.value.trim())
  }

  const handleClassbySelectOrSearch = (listuser, value, text) => {
    let root_ = listuser;

    if (value.value !== 0) {
      if (text) {
        root_ = listuser.filter((e) => e.id_role === value.value &&
          toSlug(e.user_name).includes(toSlug(text)));
      } else {
        root_ = listuser.filter((e) => e.id_role === value.value);
      }

    } else {
      if (text) { root_ = listuser.filter((e) => toSlug(e.user_name).includes(toSlug(text))); }
    }
    setRoleSearch(root_.filter(e => e.is_rank > Number(localStorage.getItem('role_rank'))))
  }


  const stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();

  const toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường       
    // xóa dấu
    str = str
      .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
    return str;
  }

  // Render
  const loading = () => {
    return <Card>
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </Card.Body>
    </Card>

  }

  const RenderSelectedRows = () => {
    let num = selectedRows.length;
    if (num > 0 && alertShow === true) {
      return (
        <Alert
          style={{
            position: "absolute",
            zIndex: "1",
            left: "91%",
          }}
          variant="info mb-0"
          onClose={() => setalertShow(false)}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  };

  const DataTable = () => {
    const columns = [
      {
        name: "STT",
        selector: (row) => row.index,
        sortable: true,
      },
      {
        name: "Họ và tên",
        selector: (row) => row.user_name,
        sortable: true,
      },
      {
        name: "Tên đăng nhập",
        selector: (row) => row.role_name,
        sortable: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      setselectedRows(items);
      setalertShow(true);
    };

    if (listuser.length > 0) {
      listuser.map((item, i) => {
        data.push({
          index:i+1,
          id: item.id,
          user_name: item.fullName,
          role_name: item.code,
          setting: (
            <>

              <Button variant="outline-warning me-2" size="sm"
                // style={{color:'white'}}
                title="Cập nhật nhân viên"
                onClick={() => {
                  setid_user(item.id);
                  getdetailuser(item.id);
                  settype("CN");
                  setShow(true);
                }}
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>



              <Button variant="outline-danger" size="sm" onClick={() => activeuser(item)} title="Xóa nhân viên">
                <i className="fa-solid fa-trash"></i>
              </Button>

            </>
          ),
        });

        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  };

  function Formuser() {
    const [username, setusername] = useState(user["Account.userName"] || "");
    const [fullName, setfullName] = useState(user.fullName);
    const [email, setemail] = useState(user.email || "");

    const [gender, setgender] = useState(user.genderId);
    const [birthday, setbirthday] = useState(user.dateOfBirth || new Date());
    const [phone, setphone] = useState(user.phoneNumber || "");
    const [contract, setcontract] = useState(user.user_contract || "");
    const [diploma, setdiploma] = useState(user.user_diploma || "");
    const [account, setaccount] = useState(user.user_account || "");
    const [password, setpassword] = useState("");


    function createuser() {
      var arrCreate = {
        fullName: fullName,
        phoneNumber: phone,
        email: email,
        genderId: gender,
        dateOfBirth: birthday,
        userName: username,
        password: password,
        statusId: 'US1',
        deleteId: 'D1'
      }

      axios.post(`/user/create`, arrCreate,)
        .then((res) => {
          if (res.data.errCode == 0) {
            getlistuser();
            handleClose();
            setid_user("");
            toast(`${res.data.message}`, {
              type: "success",
              autoClose: 1000,
            });
          } else {
            toast(`${res.data.mess}`, { type: "error", autoClose: 1000 });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    function updateuser() {
      var arrCreate = {
        fullName: fullName,
        phoneNumber: phone,
        email: email,
        genderId: gender,
        dateOfBirth: birthday,
        userName: username,
        password: password,
        statusId: 'US1',
        deleteId: 'D1'
      }

      swal({
        title: "Bạn muốn cập nhật nhân viên?",
        icon: 'warning',
        buttons: ['Đóng', 'Cập nhật'],
        successMode: true,
      }).then((ok) => {
        if (ok) {
          axios.put(`/user/update/${id_user}`, arrCreate)
            .then((res) => {
              if (res.data.errCode == 0) {
                getlistuser();
                handleClose();
                setid_user("");
                toast(`${res.data.message}`, {
                  type: "success",
                  autoClose: 1000,
                });
              } else {
                toast(`${res.data.errMessages}`, { type: "error", autoClose: 1000 });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      if (phone !== "") {
        if (!phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/)) {
          toast("Sai định dạng điện thoại", {
            type: "error",
            autoClose: 1000,
          });
          return;
        }
      }
      if (type === "TM") {
        createuser();
      } else {
        updateuser();
      }
    }


    return (
      <Modal show={show} size="md" onHide={handleClose} backdrop="static" keyboard={false} animation={false} >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa-solid fa-chalkboard-user"></i>&nbsp;
            {type === "TM" ? "Thêm nhân viên" : "Chi tiết nhân viên"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>


            <Row>
              <Col md={12}>
                <Form.Floating className="mb-3">
                  <Form.Control type="text" onChange={(e) => setfullName(e.target.value)} defaultValue={user.fullName} placeholder="Họ và tên" required />
                  <Form.Label>Họ và tên<i className="text-danger">*</i></Form.Label>
                </Form.Floating>
              </Col>



            </Row>
            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Select
                    defaultValue={user.genderId}
                    onChange={(e) => {
                      setgender(e.target.value)
                    }}
                    required
                  >
                    <option value="">--Chọn--</option>
                    <option value="M">Nam</option>
                    <option value="F">Nữ</option>
                    <option value="O">Khác</option>
                  </Form.Select>
                  <Form.Label>
                    Giới tính<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <input
                    type="date"
                    className="form-control"
                    required
                    defaultValue={moment(new Date(user.dateOfBirth)).format('YYYY-MM-DD')}
                    onChange={(e) => {
                      setbirthday(e.target.value)
                    }}
                  />
                  <Form.Label>
                    Ngày sinh<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control className="form-control" defaultValue={phone} onChange={(e) => { setphone(e.target.value) }} placeholder="Số điện thoại" required />
                  <Form.Label>Số điện thoại</Form.Label>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control type="text" onChange={(e) => setemail(e.target.value)} defaultValue={user.email} placeholder="Email" required />

                  <Form.Label>Email<i className="text-danger">*</i></Form.Label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control type="text" onChange={(e) => setusername(e.target.value)} defaultValue={user["Account.userName"]} placeholder="Tài khoản" />
                  <Form.Label>Tài khoản<i className="text-danger"></i></Form.Label>
                </Form.Floating>
              </Col>
              <Col md={6}>
                <Form.Floating className="mb-3">
                  <Form.Control type="password" onChange={(e) => setpassword(e.target.value)} defaultValue={user.password || ""} placeholder="Mật khẩu" />
                  <Form.Label>Mật khẩu<i className="text-danger"></i></Form.Label>
                </Form.Floating>
              </Col>
            </Row>

          </Modal.Body>

          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={handleClose}>
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            {type === "TM" ?
              <Button size="sm" type="submit" variant="success">
                <i className="fa-solid fa-check"></i> Lưu
              </Button>
              :
              <Button size="sm" type="submit" variant="success">
                <i className="fa-solid fa-pencil"></i> Cập nhật
              </Button>
            }
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  return (
    <Container fluid>

      <RenderSelectedRows />

      <Breadcrumb className="mb-2 mt-2 flex justify-content-start">
        <Breadcrumb.Item >Nhân viên</Breadcrumb.Item>
        <Breadcrumb.Item active>Danh sách</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <Card.Title>
              <i className="fas fa-list me-1" />
              Danh sách nhân viên
            </Card.Title>

            <div>
              <Button type="button" className="mt-3 mt-md-0" variant="success" size="sm" onClick={() => {
                settype("TM"); setShow(true);
              }} title='Thêm nhân viên'>
                <i className="fa-solid fa-plus"></i> Thêm
              </Button>
            </div>

          </div>
        </Card.Header>
        <Card.Body>
          {/* {renderSearchFilter()} */}
          {
            // checkload ? loading() :
            <DataTable />
          }

        </Card.Body>
      </Card>
      {
        show ?
          <Formuser /> : ""
      }
    </Container>
  );
}
