import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Footer from "../../../layouts/frontend/footer/Footer";
// import Navbar from "../../../layouts/frontend/Navbar";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Row,
} from "react-bootstrap";
// import 'react-toastify/dist/ReactToastify.css';
// import Loader from "../../../layouts/frontend/Loader";
import './login.css'
function Login() {

  useEffect(() => {
    document.title = "Đăng nhập";
  }, []);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [loginInput, setLogin] = useState({
    username: localStorage.getItem("loginName") || "",
    password:"",
  
  });

  const setType = (num) => {
    setLogin({ ...loginInput, type: num });
  };

  const handleInput = (e) => {
    e.persist();

    let field = e.target.name;
    let value = e.target.value;

    setLogin({ ...loginInput, [field]: value });
  }

  const loginSubmit = (e) => {
    e.preventDefault();
    axios.post('/login', loginInput)
      .then(res => {
        if (res.data.errCode == 0) {
         
          localStorage.setItem("access_token", res.data.accessToken);
          localStorage.setItem("fullName", res.data.userData.fullName);
          localStorage.setItem("loginName", res.data.userData.code);
          localStorage.setItem("idLogin", res.data.userData.id);

          
          toast(res.data.message, { type: "success", autoClose: 1000 });
          window.location.href = "/admin";
        }
        else {
          toast(res.data.errMessages.username || res.data.errMessages.password, { type: "error", autoClose: 1000 });
        }

      })
      .catch(error => {
        console.error( error);
      });
   
  }

  return (

    <Container className="py-5" id="login">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="text-center">
            <Form onSubmit={loginSubmit}>
              <Card.Header className="cardheader">
                {/*   <Nav variant="tabs" defaultActiveKey={"user" + loginInput.type} >
                          <Nav.Item className="w-50">
                            <Nav.Link style={{ fontWeight: "bold" }} eventKey={"user2"} onClick={() => setType(2)} >
                              Nhân viên
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item className="w-50">
                            <Nav.Link style={{ fontWeight: "bold" }} eventKey={"user1"} onClick={() => setType(1)} >
                            Quản lý
                            </Nav.Link>
                          </Nav.Item>
                        </Nav> */}
                ĐĂNG NHẬP
              </Card.Header>

              <Card.Body>
                <Form.Floating className="mb-3">
                  <Form.Control
                    onChange={handleInput}
                    defaultValue={loginInput.username}
                    name="username"
                    type="text"
                    placeholder="Số điện thoại"
                    required
                  />
                  <Form.Label>
                    <i className="fa-solid fa-user" /> Tên đăng nhập
                  </Form.Label>
                </Form.Floating>
                <Form.Floating className="mb-3">
                  <InputGroup className="has-validation w-100 is-invalid">
                    <Form.Floating
                      style={{ width: "calc(100% - 46px)" }}
                    >
                      <Form.Control
                        onChange={handleInput}
                        defaultValue={loginInput.password}
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        className="border-end-0"
                        placeholder="Mật Khẩu"
                        required
                      />
                      <Form.Label>
                        <i className="fa-solid fa-key" /> Mật Khẩu
                      </Form.Label>
                    </Form.Floating>

                    <InputGroup.Text onClick={togglePassword} style={{ width: "46px" }} >
                      <i className={passwordShown ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"} />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Floating>

              </Card.Body>

              <Card.Footer>
                <Col sm={6} className="d-grid gap-2 mx-auto">
                  <Button type="submit" variant="warning">
                    Đăng nhập <i className="fa-solid fa-right-to-bracket" />
                  </Button>
                </Col>
              </Card.Footer>

            </Form>
          </Card>
        </Col>
      </Row>
      <br />
      {/* <div className="text-center" style={{fontSize:"15px"}}>  Chưa có tài khoản? <Link to='/register'>Đăng ký ngay</Link>
         
            </div> */}
    </Container>

  );
}

export default Login;
