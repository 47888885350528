import React, {useEffect} from 'react';
import {Outlet, Navigate } from 'react-router-dom'; 

// import '../../assets/admin/css/styles.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import './masterLayout.css'
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import Footer from '../footer/Footer';

function MasterLayout () {
    // useEffect(() => {
    //     axios.get(`/checkLogin?date=${Date.now()/1000}`).then(res => {
    //         if(res.data.status === false) {
    //             toast(res.data.data.mess, { type: "error", autoClose: 10});
    //             getLocalStore();
    //         }else{
    //             localStorage.setItem("fullName", res.data.data.user_name);
    //             localStorage.setItem("role_rank", res.data.data.role_rank);
    //             localStorage.setItem("role_id", res.data.data.role_id);
    //             localStorage.setItem("role_name", res.data.data.role_name);
    //             localStorage.setItem("permission", res.data.data.permission);
    //             localStorage.setItem("vip",res.data.data.vip);
    //         }
    //     });
    // }, []);
    if (!localStorage.getItem('access_token')) {
        toast("Bạn chưa đăng nhập!", { type: "error" });
        return <Navigate to="/login" />;
    }
    const getLocalStore = () => {
        localStorage.removeItem("auth_id_shool");
        localStorage.removeItem("auth_id_user");
        localStorage.removeItem("fullName");
        localStorage.removeItem("role_rank");
        localStorage.removeItem("role_id");
        localStorage.removeItem("role_name");
        localStorage.removeItem("permission");
        localStorage.removeItem("token_type");
        localStorage.removeItem("access_token");
        localStorage.removeItem("auth_phone");
        localStorage.removeItem("auth_account");
        localStorage.removeItem("vip");
        window.location.href = "/";
    }

    const sidenavToggle = () => {
        const sbNavFixed = document.getElementById('sb-nav-fixed');
        //sbNavFixed.classList.remove('sb-sidenav-toggled');

        let testClass = sbNavFixed.className;
        
        if(testClass === 'sb-nav-fixed sb-sidenav-toggled' && window.innerWidth < 992){
            sbNavFixed.classList.remove('sb-sidenav-toggled');
        }
    }

    if(!localStorage.getItem('access_token')) {
        toast("Bạn chưa đăng nhập.", {type: "error"});
        return <Navigate to="/login" />; 
    }

    return (
        <div className='sb-nav-fixed' id="sb-nav-fixed">
       
            <Navbar />
        
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav" className='bg-dark'>
                    <Sidebar />
                </div>

                <div id="layoutSidenav_content_" onClick={sidenavToggle}>
                    <main>
                        <Outlet />
                    </main>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default MasterLayout;
