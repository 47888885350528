import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import '../../assets/client/css/responsive.css'
// import '../../assets/client/css/style.css'
// import './style.css'
// import '../../assets/client/css/style.css'
// import './navbarN.css'
// import Logo from '../../assets/client/images/logo.png'
import jQuery from 'jquery';
import { Container } from 'react-bootstrap';
function Navbar() {
  const navigate = useNavigate();
  const params = useParams()
  const [ShowDropdown, setShowDropdown] = useState(false)

  const sidenavToggle = () => {
    const navbarSupportedContent = document.getElementById('navbarSupportedContent');

    let testclass = navbarSupportedContent.className;

    if (testclass === 'navbar-collapse collapse show' && window.innerWidth < 992) {
      navbarSupportedContent.classList.remove('show');
    }
  }
  (function ($) {
    $(function () {

      //  open and close nav 
      $('#navbar-toggle').click(function () {
        // $('nav ul').slideToggle();
        $('nav ul').toggle()

      });
      // Hamburger toggle
      $('#navbar-toggle').on('click', function () {
        this.classList.toggle('active');

      });
      // If a link has a dropdown, add sub menu toggle.
      $('nav ul li a:not(:only-child)').click(function (e) {
        $(this).siblings('.navbar-dropdown').slideToggle("slow");
        // Close dropdown when select another dropdown
        $('.navbar-dropdown').not($(this).siblings()).hide("slow");
        e.stopPropagation();
      });
      // Click outside the dropdown will remove the dropdown class
      $('html').click(function () {
        $('.navbar-dropdown').hide();
      });
    });
  })(jQuery);


  return (

    <section className="navigation">
      <div className=" container nav-container">
        {window.location.pathname == "/" ?
          <div className="brand">
            {/* <Link to={"/"}>Logo</Link> */}
            <div
              // className="all-category"
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
            >


              {/* <div className="myDIV">
                <span className='menu_nav'>
                  <i className="fa fa-bars" aria-hidden="true" ></i> Danh mục
                </span></div> */}
              {ShowDropdown ? (
                < ul className='menunav'>
                  <li><Link to={"/"}> Sản phẩm dùng hàng ngày </Link>
                    <ul

                    >
                      <li><Link to={"/"}>Vitamin</Link></li>
                      <li><Link to={"/"}>Sữa</Link></li>
                      <li><Link to={"/"}>Thực phẩm chức năng</Link></li>


                    </ul>
                  </li>

                  <li><Link to={"/"}>Sản phẩm chăm sóc sức khỏe</Link></li>
                </ul>
              )
                : ""
              }

            </div>
          </div> : ""
        }
        <nav>
          <div className="nav-mobile"><Link id="navbar-toggle" to={"/"}><span></span></Link></div>
          <ul className="nav-list">
            <li>
              <Link to={"/"}>
                <span className='menu_nav'>
                  Trang chủ
                </span>
              </Link>
            </li>
            <li>
              <Link to={"/product"}>
                <span className='menu_nav'>
                  Sản phẩm
                </span>
              </Link>
            </li>
            {/* <li>
                <Link to={"/"}>Services</Link>
                <ul class="navbar-dropdown">
                  <li>
                    <Link to={"/"}>Sass</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Less</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Stylus</Link>
                  </li>
                </ul>
              </li> */}
            {/* <li>
                <Link to={"/"}>Portfolio</Link>
              </li> */}
            {/* <li>
                <Link to={"/"}>Category</Link>
                <ul class="navbar-dropdown">
                  <li>
                    <Link to={"/"}>Sass</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Less</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Stylus</Link>
                  </li>
                </ul>
              </li> */}
            <li>
              <Link to={"/about"} >
                <span className='menu_nav'>
                Giới thiệu
                </span>
              </Link>

            </li>
            <div className='text_menu' >
              úm  bá laaaaa
            </div>
          </ul>
        </nav>


      </div>
    </section>

  )
}

export default Navbar;

