import { Component } from "react";
import CurrencyFormat from "react-currency-format";

class FormatData extends Component {
    date(data){

        let date = new Date(data * 1000);
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getHours()}`;
    }
    
    dateNotTime(data){
        let d = new Date(data * 1000);
        return d.getDate() + '/' + d.getMonth() + 1 + '/' + d.getFullYear();
    }

    dateNotTime1(data){
        let d = new Date(data);
        return d.getDate() + '/' + d.getMonth() + 1 + '/' + d.getFullYear();
    }

    currency(data){
        if(data !== null){
            return <CurrencyFormat 
                        value={data} 
                        displayType={'text'} 
                        thousandSeparator={true} 
                        // suffix=" ₫" 
                    />;
        }
    }

    toSlug(str){
        if(str !== 'undefined' ){
            str = str.toLowerCase(); // Chuyển hết sang chữ thường       
            str = str
              .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
              .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
            str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
            str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
        }
       
        return str;
    }
}

export default FormatData;