import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Form, Col, Row,Card } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../datatable/DataTables';
import TopicAddModal from './TopicAddModal';
import moment from 'moment';
import TopicEditModal from './TopicEditModal';
import swal from 'sweetalert';

const TopicIndex = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Chủ đề";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getTopicAll();
    }, [navigate]);

    const [topics, setTopics] = useState([]);
    const [showTopicAddModal, setShowTopicAddModal] = useState(false);
    const [showTopicEditModal, setShowTopicEditModal] = useState(false);
    const [idEdit, setIdEdit] = useState('');

    // const [selectedRows, setselectedRows] = useState([]);
    // const [alertShow, setalertShow] = useState(false);

    const getTopicAll = async () => {
        await axios.get(`/topic/list/all?limit=${20}&page=${1}`).then((res) => {
            if (res.data.errCode === 0) {
                setTopics(res.data.topics);
            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const editTopic = (id) => {
        setShowTopicEditModal(true);
        setIdEdit(id);
    }

    const deleteTopic = async (id, name) => {
        await swal({
            title: "Bạn muốn xóa chủ đề?",
            text: name,
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                await axios.delete(`/topic/destroy/${id}`).then((res) => {
                    if (res.data.errCode === 0) {
                        getTopicAll();
                        toast(res.data.message, { type: 'success' });
                    } else {
                        toast(res.data.message, { type: 'error' });
                    }
                }).catch((error) => {
                    toast(error.message, { type: 'error' });
                });
            }
        })

    }

    const showTopicAll = () => {
        const columns = [
            {
                name: "Tên chủ đề",
                selector: (row) => row.nameVi,
                sortable: true,
            },
            {
                name: "Cập nhật",
                selector: (row) => row.updatedAt,
                sortable: true,
                right: true,
                cell: row => <>{moment(row.updatedAt).format('DD/MM/YYYY hh:mm:ss')}</>
            },
            {
                name: "Phương thức",
                // selector: (row) => row.setting,
                center: true,

                cell: row => <>
                    <Button onClick={() => editTopic(row.id)} variant='outline-warning' size="sm" className="me-2" type="button" title='Cập nhật chủ đề'>
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>

                    <Button onClick={() => deleteTopic(row.id, row.nameVi)} variant='outline-danger' size="sm" type="button"  title='Xóa chủ đề'>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </>
            },
        ];

        const data = [];

        // const selectedRows = (items) => {
        //     setselectedRows(items);
        //     setalertShow(true);
        // };

        if (topics.length > 0) {
            topics.map((item, i) => {
                data.push({
                    id: item.id,
                    nameVi: item.nameVi,
                    updatedAt: item.updatedAt,
                });
                return item;
            });
        }

        return (<>
            {/* <Row className='bg-light p-2'>
                <Col md={3}>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Tìm kiếm"
                            className="me-2"
                            aria-label="Search"
                        />
                        <Button variant="outline-secondary" ><FontAwesomeIcon icon={faSearch} /></Button>
                    </Form>
                </Col>

                <Col md={4}>
                    <Button variant="outline-success" onClick={() => setShowTopicAddModal(true)}>
                        <FontAwesomeIcon icon={faPlus} /> Thêm
                    </Button>
                </Col>
            </Row> */}
            <Card>
                <Card.Header>
                    <div className="d-block d-md-flex justify-content-between">
                        <Card.Title>
                            <i className="fas fa-list me-1" />
                            Danh sách chủ đề
                        </Card.Title>

                        <div>
                           
                            <Button variant="success" onClick={() => setShowTopicAddModal(true)}>
                        <FontAwesomeIcon icon={faPlus} /> Thêm
                    </Button>
                        </div>

                    </div>
                </Card.Header>
                <Card.Body>
                    {/* {renderSearchFilter()} */}
                    {
                        // checkload ? loading() :
                        <DataTables data={data} columns={columns}
                        // selectedRows={selectedRows} 
                        />
                    }

                </Card.Body>
            </Card>
           
        </>
        )
    }

    const onSubmitOk = () => {
        setShowTopicAddModal(false);
        setShowTopicEditModal(false);
        getTopicAll();
    }

    return (<>
        <div className='container'>
            <Breadcrumb className="p-2  d-flex justify-content-start">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                   Tin tức
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Chủ đề</Breadcrumb.Item>
            </Breadcrumb>
            {/* <RenderSelectedRows /> */}

            {showTopicAll()}

            {showTopicAddModal ? <TopicAddModal show={showTopicAddModal} onHide={() => setShowTopicAddModal(false)} onSubmitSuccess={onSubmitOk} /> : <></>}
            {showTopicEditModal && idEdit !== '' ? <TopicEditModal show={showTopicEditModal} onHide={() => setShowTopicEditModal(false)} idEdit={idEdit} onSubmitSuccess={onSubmitOk} /> : <></>}

        </div>
    </>
    )
}

export default TopicIndex;




