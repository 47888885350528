import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import '../../assets/home/css/style.css';
import { Container, NavDropdown, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useCart } from "react-use-cart";
import { faCartPlus, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
// import Logo from '../../../assets/client/images/logo.png'
// import IconLogo from '../../../assets/client/images/logo-vplife-icon.png'
import { faFacebook, faFacebookF, faInstagramSquare, faTwitterSquare, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../../assets/client/images/logo_white.png'
import LogoIcon from '../../../assets/client/images/LogoIcon.png'

import './navbarMiddle.css'
const NavbarMiddle = () => {
    const navigate = useNavigate();

    // const { totalUniqueItems } = useCart();

    const [visibleCategory, setVisibleCategory] = useState(false);

    const { category_slug, search } = useParams();
    const [keyword, setKeyword] = useState(search ? search : '');

    const submitSearch = (e) => {
        e.preventDefault();

        navigate(`/danh-muc/${category_slug || 'tat-ca'}/${keyword}`);
    }

    return (
        <div id="header" className="top-head_ bg_white">
            {/* <Row> */}

            <div className='bg-white'>
            <div className='hotline container '>
              
              <a className='mb-3'>
                  {/* <FontAwesomeIcon icon={faPhone} /> */}
                  Giao hàng toàn quốc | Miễn phí ship
              </a>

              <div className='icon_'>
                  <ul className="list-inline socials">


                      <li className='list-inline-item ' style={{ whiteSpace: 'nowrap' }}>
                          <Link to="/login" className=' btn-login'>
                              <FontAwesomeIcon icon={faUser} />  Đăng nhập
                          </Link>
                      </li>
                      
                  </ul>
              </div>
          </div>
            </div>
         
            {/* </Row>  */}
            <Container style={{ backgroundColor: '#33333' }}>
                {/* <Row> */}

                {/* </Row> */}
                <Row className='row-header mt-2'>
                    <Col lg={4} md={4} sm={4} xs={3} className='company '>
                        <Link to="/" className='companyinfo'>
                            {/* <h1>
                                Ngọc Hằng Store
                            </h1> */}
                            <img className='navbar_logo me-md-1' src={Logo} alt="logo" />
                            <img className='navbar_logo_mobile' src={LogoIcon} alt ='logo'/>
                            {/* <span className='d-none d-sm-block'></span> */}
                        </Link>
                    </Col>


                    <Col lg={5} md={4} sm={5} xs={5} className='menu'>
                        <div className='row-item-center d-flex align-items-center justify-content-end'>

                            <Form onSubmit={submitSearch} style={{ width: '80%' }}>
                                <InputGroup className="search-input">
                                    <Form.Control className='noboder' onChange={(e) => setKeyword(e.target.value)} value={keyword}


                                        placeholder="Tìm kiếm" />
                                    <div className="button">
                                        <a href="#" className="btn">   <FontAwesomeIcon icon={faMagnifyingGlass} className=' me-2 mt-1' /></a>
                                    </div>
                                   
                                </InputGroup>
                            </Form>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={3} xs={4} className='menu ps-3 text-right'>
                        <div className='row-item-right d-flex justify-content-end'>
                            <div className='text-white d-flex align-items-center text-right'>
                              
                                    <div className='d-flex justify-content-end '
                                    >
                                          <div className='hot_'>
                                    <FontAwesomeIcon icon={faPhone} style={{color:'#fff'}} /> <span>  0358928123 </span>

                                    </div>

                                </div>


                            </div>

                            {/* <div className='account_home mt-2'><FontAwesomeIcon icon={faUser} />
                                <NavDropdown title={<> <span className='title'>Tài khoản</span></>} >
                                    <NavDropdown.Item as={Link} to={`/login/`} className="btn" >Đăng nhập
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to='/register' className="btn">Đăng ký
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </div> */}
                        </div>
                    </Col>


                </Row>
            </Container >

            {/* <Sidebar show={visibleCategory} onHide={() => setVisibleCategory(false)} /> */}
        </div >
    )
}

export default NavbarMiddle;

