import React from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
// import Footer from '../footer/Footer';
import NavbarMiddle from '../navbarMiddle/Navbar_middle';
import Navbar from '../navbarN/NavBar_N';
function MasterLayout_Client() {
    // const top_up = () => {
    //     window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
    const sidenavToggle = () => {
        const navbarSupportedContent = document.getElementById('navbarSupportedContent');

        let testClass = navbarSupportedContent.className;

        if (testClass === 'navbar-collapse collapse show' && window.innerWidth < 992) {
            navbarSupportedContent.classList.remove('show');
        }
    }

    return (
        <div className='sb-nav-fixed' id="sb-nav-fixed">
            {/* <Navbar /> */}
            <NavbarMiddle />
            <Navbar />
            <div id="layoutSidenav" onClick={sidenavToggle}>
                <div id="layoutSidenav_content" >
                    <main>


                        <Outlet />
                    </main>
                    {/* <Footer /> */}
                </div>
            </div >
        </div >
    );
}

function myParams(Component) { //Back link AND use "params"
    return props => <Component navHook={useNavigate()} />;
}

export default myParams(MasterLayout_Client);
