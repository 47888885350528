import React, { useState, useEffect } from 'react';
import { Card, Col, FloatingLabel, Form, Nav, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import FormatData from '../../other/FormatData';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import TextEditor from './TextEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faCheck, faChevronDown, faChevronUp, faMinus, faPencil, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import CurrencyFormat from 'react-currency-format';
import FormatData from '../other/FormatData';

const ProductEditModal = (props) => {
    const [products, setProducts] = useState([{
        code: '',
        unitId: '',
        nameVi: '',
        image: '',
        price: '',
        priceNew: '',
        errors: [],
    }]);

    const id = props.idEdit || '';

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getCategories();
        getUnits();
        getProduct();
    }, []);

    const [content, setContent] = useState({
        categoryId: '',
        slug: '',
        keyword: '',
        titleVi: '',
        descVi: '',
        contentVi: '',
        images: [],
        errors: [],
    });

    const [categories, setCategories] = useState([]);
    const [units, setUnits] = useState([]);
    const [viewKey, setViewKey] = useState(0);

    const getProduct = async () => {
        if (id !== '') {
            await axios.get(`/product/${id}`).then((res) => {
                if (res.data.errCode === 0) {
                    let dataContent = res.data.content;
                    let dataImages = res.data.images;
                    let dataProducts = res.data.products;

                    content.categoryId = dataContent.categoryId;
                    content.slug = dataContent.slug;
                    content.keyword = dataContent.keyword;
                    content.titleVi = dataContent.titleVi;
                    content.descVi = dataContent.descVi;
                    content.contentVi = dataContent.contentVi;

                    if (dataImages.length > 0) {
                        dataImages.map((item, i) => {
                            content.images[i] = item['Image.src'];
                            return item;
                        });
                    }

                    setContent({ ...content });

                    let productsArr = [];

                    if (dataProducts.length > 0) {
                        dataProducts.map((item, i) => {
                            productsArr.push({
                                code: item['code'],
                                unitId: item['unitId'],
                                nameVi: item['nameVi'],
                                image: item['Image.src'],
                                price: item['price'],
                                priceNew: item['priceNew'],
                                errors: [],
                            });

                            return item;
                        });
                        setProducts(productsArr);
                    }

                } else {
                    toast(res.data.errMessage, { type: 'error' });
                }
            }).catch((error) => {
                toast(error.message, { type: 'error' });
            });
        }
    }

    console.log(products);
    const getUnits = async () => {
        await axios.get(`/unit/list?type=PRODUCT&status=active`).then((res) => {
            if (res.data.errCode === 0) {
                setUnits(res.data.units);
            }
        }).catch((e) => {
            console.log(e);
        });
    }

    const getCategories = async () => {
        await axios.get(`/category/list/active?limit=${100}&page=${1}`).then((res) => {
            if (res.data.errCode === 0) {
                setCategories(res.data.categories);
            }
        }).catch((e) => {
            console.log(e);
        });
    }

    const handleClose = () => props.onHide();

    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        content[field] = value;
        delete (content.errors[field]);

        if (field === 'titleVi') {
            let convertToSlug = FormatData.convertToSlug(value);
            delete (content.errors.slug);
            content.slug = convertToSlug;
        }

        setContent({ ...content });
    }

    const handleInputTextEditor = (data) => {
        let field = data.field;
        let value = data.value;

        content[field] = value;
        delete (content.errors[field]);

        setContent({ ...content });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            categoryId: content.categoryId,
            slug: content.slug,
            keyword: content.keyword,
            titleVi: content.titleVi,
            descVi: content.descVi,
            images: {
                0: content.images[0] || '',
                1: content.images[1] || '',
                2: content.images[2] || '',
                3: content.images[3] || '',
            },
            products: products
        };

        await axios.post(`/product/create`, data).then((res) => {
            if (res.data.errCode === 1) {
                let contentData = { ...content };
                contentData.errors = res.data.errMessages;
                setContent(contentData);
                toast('Thêm sản phẩm thất bại.', { type: 'error' });
            } else {
                const { onSubmitSuccess } = { ...props };
                onSubmitSuccess();
                toast(res.data.message, { type: 'success' });
            }
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }

    const [showDesc, setShowDesc] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [showImages, setShowImages] = useState(false);

    const renderContent = () => {
        const handleInputImage = (event) => {
            let field = event.target.name;
            let value = event.target.value;
            let key = event.target.getAttribute("data-key");
            content[field][key] = value;
            delete (content.errors[`${field}.${key}`]);
            setContent({ ...content });
        }

        const optionCategory = () => {
            if (categories.length > 0) {
                return categories.map((item, i) => {
                    return <option value={item.id} key={`category${item.id}`}>{item.nameVi}</option>
                })
            }
        }

        return (<>
            <Row>
                <Col md={12}>
                    <Form.Floating className="mb-3">
                        <Form.Control type="text" onChange={(e) => handleInput(e)} name="titleVi" defaultValue={content.titleVi} className={content.errors.titleVi ? 'is-invalid' : ''} placeholder="Tên chủ đề" />
                        <Form.Label>Tiêu đề<i className="text-danger">*</i></Form.Label>
                        <span className='text-danger'>{content.errors.titleVi ? content.errors.titleVi : ''}</span>
                    </Form.Floating>
                </Col>

                <Col md={12}>
                    <Form.Floating className="mb-3">
                        <Form.Control type="text" onChange={(e) => handleInput(e)} name="slug" defaultValue={content.slug} className={content.errors.slug ? 'is-invalid' : ''} placeholder="Slug" />
                        <Form.Label>Slug<i className="text-danger">*</i></Form.Label>
                        <span className='text-danger'>{content.errors.slug ? content.errors.slug : ''}</span>
                    </Form.Floating>
                </Col>

                <Col md={6}>
                    <FloatingLabel className="mb-3" controlId="categorySelect" label={<>Danh mục<i className="text-danger">*</i></>}>
                        <Form.Select onChange={(e) => handleInput(e)} defaultValue={content.categoryId} name='categoryId' className={content.errors.categoryId ? 'is-invalid' : ''}>
                            <option value=''>--Chọn--</option>
                            {optionCategory()}
                        </Form.Select>
                        {content.errors.categoryId ? <span className='text-danger'>{content.errors.categoryId}</span> : null}
                    </FloatingLabel>
                </Col>

                <Col md={6}>
                    <Form.Floating className="mb-3">
                        <Form.Control as="textarea" onChange={(e) => handleInput(e)} name="keyword" defaultValue={content.keyword} className={content.errors.keyword ? 'is-invalid' : ''} placeholder="Từ khóa" />
                        <Form.Label>Từ khóa</Form.Label>
                        {content.errors.keyword ? <span className='text-danger'>{content.errors.keyword}</span> : null}
                    </Form.Floating>
                </Col>

                <Col md={12} className='mb-2'>
                    <Form.Label className='fw-bold'>
                        <Button variant='outline-secondary' size='sm' className='me-2' onClick={() => setShowDesc(!showDesc)}>
                            <FontAwesomeIcon icon={!showDesc ? faChevronDown : faChevronUp} />
                        </Button>
                        Mô tả ngắn gọn:
                    </Form.Label>
                    <div className={showDesc ? 'd-block' : 'd-none'} >
                        <TextEditor setContents={content.descVi} onChange={(value) => handleInputTextEditor({ field: 'descVi', value: value })} placeholder="Mô tả" />
                    </div>
                    {content.errors.descVi ? <span className='text-danger'>{content.errors.descVi}</span> : null}
                </Col>

                <Col md={12} className='mb-2'>
                    <Form.Label className='fw-bold'>
                        <Button variant='outline-secondary' size='sm' className='me-2' onClick={() => setShowContent(!showContent)}>
                            <FontAwesomeIcon icon={!showContent ? faChevronDown : faChevronUp} />
                        </Button>
                        Thông tin chi tiết:
                    </Form.Label>
                    <div className={showContent ? 'd-block' : 'd-none'} >
                        <TextEditor setContents={content.contentVi} onChange={(value) => handleInputTextEditor({ field: 'contentVi', value: value })} placeholder="Thông tin chi tiết" />
                    </div>
                    {content.errors.contentVi ? <span className='text-danger'>{content.errors.contentVi}</span> : null}
                </Col>

                <Form.Label className='fw-bold'>
                    <Button variant='outline-secondary' size='sm' className='me-2' onClick={() => setShowImages(!showImages)}>
                        <FontAwesomeIcon icon={!showImages ? faChevronDown : faChevronUp} />
                    </Button>
                    URL hình ảnh:
                </Form.Label>

                <Col md={6} className={showImages ? 'd-block' : 'd-none'}>
                    <Form.Floating className="mb-3">
                        <Form.Control type="text" onChange={(e) => handleInputImage(e)} name="images" data-key='0' defaultValue={content.images[0]} className={content.errors[`images.0`] ? 'is-invalid' : ''} placeholder="Hình 1" />
                        <Form.Label>Hình 1</Form.Label>
                        {content.errors[`images.0`] ? <span className='text-danger'>{content.errors[`images.0`]}</span> : null}
                    </Form.Floating>
                </Col>

                <Col md={6} className={showImages ? 'd-block' : 'd-none'}>
                    <Form.Floating className="mb-3">
                        <Form.Control type="text" onChange={(e) => handleInputImage(e)} name="images" data-key='1' defaultValue={content.images[1]} className={content.errors[`images.1`] ? 'is-invalid' : ''} placeholder="Hình 2" />
                        <Form.Label>Hình 2</Form.Label>
                        {content.errors[`images.1`] ? <span className='text-danger'>{content.errors[`images.1`]}</span> : null}
                    </Form.Floating>
                </Col>

                <Col md={6} className={showImages ? 'd-block' : 'd-none'}>
                    <Form.Floating className="mb-3">
                        <Form.Control type="text" onChange={(e) => handleInputImage(e)} name="images" data-key='2' defaultValue={content.images[2]} className={content.errors[`images.2`] ? 'is-invalid' : ''} placeholder="Hình 3" />
                        <Form.Label>Hình 3</Form.Label>
                        {content.errors[`images.2`] ? <span className='text-danger'>{content.errors[`images.2`]}</span> : null}
                    </Form.Floating>
                </Col>

                <Col md={6} className={showImages ? 'd-block' : 'd-none'}>
                    <Form.Floating className="mb-3">
                        <Form.Control type="text" onChange={(e) => handleInputImage(e)} name="images" data-key='3' defaultValue={content.images[3]} className={content.errors[`images.3`] ? 'is-invalid' : ''} placeholder="Hình 4" />
                        <Form.Label>Hình 4</Form.Label>
                        {content.errors[`images.3`] ? <span className='text-danger'>{content.errors[`images.3`]}</span> : null}
                    </Form.Floating>
                </Col>
            </Row >
        </>);
    }

    const addProductItem = () => {
        let productData = products;
        if (productData.length > 0) {
            productData.push({
                code: '',
                unitId: '',
                image: '',
                price: '',
                priceNew: '',
                errors: [],
            });
            setProducts(productData);
        }

        setIsLoadProduct(!isLoadProduct);
    }

    const deleteProductItem = (key) => {
        let productData = products;
        delete (productData[key]);
        setProducts(productData);

        setIsLoadProduct(!isLoadProduct);
    }

    const [isLoadProduct, setIsLoadProduct] = useState(false);

    const pruductItems = () => {
        let n = 0;

        const optionUnit = () => {
            if (units.length > 0) {
                return units.map((item, i) => {
                    return <option value={item.id} key={`unit${item.id}`}>{item.symbol}</option>
                })
            }
        }


        if (products.length > 0) {
            return products.map((item, i) => {
                n++;
                const handleInputProduct = (e) => {
                    let value = e.target.value;
                    let field = e.target.name;

                    products[i][field] = value;

                    delete (content.errors[`products.${i}.${field}`]);

                    setContent(content);
                    setProducts(products);
                    setIsLoadProduct(!isLoadProduct);
                }

                const handleInputCurrency = (field, value) => {
                    products[i][field] = value;
                    delete (content.errors[`products.${i}.${field}`]);
                    setContent(content);
                    setProducts(products);
                    setIsLoadProduct(!isLoadProduct);
                }

                return (
                    <Row key={'productItem' + i}>
                        <Col md={12} className="mb-3">
                            <h5>Sản phẩm {n}
                                {i > 0 ?
                                    <Button variant='outline-danger' type='button' size='sm' className='ms-2' onClick={() => deleteProductItem(i)}>
                                        <FontAwesomeIcon icon={faTrash} /> Xóa
                                    </Button>
                                    : null
                                }
                            </h5>
                        </Col>

                        <Col md={6}>
                            <Form.Floating className="mb-3">
                                <Form.Control type="text" onChange={(e) => handleInputProduct(e)} name="code" defaultValue={item.code} placeholder="Mã sản phẩm" />
                                <Form.Label>Mã sản phẩm<i className="text-danger">*</i></Form.Label>
                                {content.errors[`products.${i}.code`] ? <span className='text-danger'>{content.errors[`products.${i}.code`]}</span> : null}
                            </Form.Floating>
                        </Col>

                        <Col md={6}>
                            <Form.Floating className="mb-3">
                                <Form.Control type="text" onChange={(e) => handleInputProduct(e)} name="nameVi" defaultValue={item.nameVi} className={item.errors.nameVi ? 'is-invalid' : ''} placeholder="Tên sản phẩm" />
                                <Form.Label>Tên sản phẩm</Form.Label>
                                {content.errors[`products.${i}.nameVi`] ? <span className='text-danger'>{content.errors[`products.${i}.nameVi`]}</span> : null}
                            </Form.Floating>
                        </Col>

                        <Col md={6}>
                            <FloatingLabel className="mb-3" controlId="categorySelect" label='Đơn vị'>
                                <Form.Select onChange={(e) => handleInputProduct(e)} name='unitId' defaultValue={item.unitId} >
                                    <option value=''>--Chọn--</option>
                                    {optionUnit()}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>

                        <Col md={6}>
                            <Form.Floating className="mb-3">
                                <Form.Control type="text" onChange={(e) => handleInputProduct(e)} name="image" defaultValue={item.image} className={item.errors.image ? 'is-invalid' : ''} placeholder="URL hình ảnh" />
                                <Form.Label>URL hình ảnh</Form.Label>
                                {content.errors[`products.${i}.image`] ? <span className='text-danger'>{content.errors[`products.${i}.image`]}</span> : null}
                            </Form.Floating>
                        </Col>

                        <Col md={6}>
                            <Form.Floating className="mb-3">
                                <CurrencyFormat
                                    value={item.price}
                                    className='form-control text-end'
                                    thousandSeparator={true}
                                    suffix={' ₫'}
                                    placeholder="Giá bán"
                                    onValueChange={(values) => handleInputCurrency('price', values.value)}
                                />
                                <Form.Label>Giá bán<i className="text-danger">*</i></Form.Label>
                                {content.errors[`products.${i}.price`] ? <span className='text-danger'>{content.errors[`products.${i}.price`]}</span> : null}
                            </Form.Floating>
                        </Col>

                        <Col md={6}>
                            <Form.Floating className="mb-3">
                                <CurrencyFormat
                                    value={item.priceNew}
                                    className='form-control text-end'
                                    thousandSeparator={true}
                                    suffix={' ₫'}
                                    placeholder="Giá khuyến mãi"
                                    onValueChange={(values) => handleInputCurrency('priceNew', values.value)}
                                />
                                <Form.Label>Giá khuyến mãi</Form.Label>
                                {content.errors[`products.${i}.priceNew`] ? <span className='text-danger'>{content.errors[`products.${i}.priceNew`]}</span> : null}

                            </Form.Floating>
                        </Col>
                        <hr></hr>
                    </Row>
                );

            });
        }

    }

    const renderProduct = () => {
        return (<>
            <div>
                {pruductItems()}

                <Button variant='outline-success' type='button' onClick={addProductItem}><FontAwesomeIcon icon={faPlus} /> Thêm</Button>
            </div>
        </>);
    }

    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title><FontAwesomeIcon icon={faPencil} /> Chi tiết sản phẩm</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Card>
                            <Card.Header>
                                <Nav variant="tabs" defaultActiveKey={'product_' + viewKey}>
                                    <Nav.Item>
                                        <Nav.Link eventKey={'product_0'} onClick={() => setViewKey(0)} >
                                            Nội dung
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey={'product_1'} onClick={() => setViewKey(1)} >
                                            Sản phẩm
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>

                            <Card.Body>
                                {
                                    viewKey === 0 ? renderContent() :
                                        viewKey === 1 ? renderProduct() :
                                            null
                                }
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            <FontAwesomeIcon icon={faTimes} /> Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            <FontAwesomeIcon icon={faCheck} /> Cập nhật
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >
        </>
    );
}

export default ProductEditModal;