import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    Alert,
    Breadcrumb,
    Button,
    Card,
    Container,
    Form,
    Modal, Col, Row, InputGroup, Table
} from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DataTables from "../datatable/DataTables";
import Select from 'react-select'
import Check from "../other/Check";
import moment from "moment/moment";
import * as XLSX from "xlsx-js-style";

export default function CategoryIndex() {
    useEffect(() => {
        document.title = "Danh mục";
        getlistuser()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [show, setShow] = useState(false);
    const [type, settype] = useState("");
    const [listcategory, setlistcategory] = useState([]);
    const [category, setcategory] = useState([]);

    const [id_user, setid_user] = useState("");

    const [selectedRows, setselectedRows] = useState([]);
    const [alertShow, setalertShow] = useState(false);
    const [RoleFilter, setRoleFilter] = useState({ value: 0, label: "Tất cả chức vụ" });

    const [checkload, setCheckload] = useState(true)

    // Fetch API
    const getlistuser = () => {
        axios.get(`/category/list/all?limit=${20}&page=${1}`)
            .then((res) => {
                if (res.data.errCode == 0) {
                    setlistcategory(res.data.categories);
                    setCheckload(false)
                    //   handleClassbySelectOrSearch(res.data.users, RoleFilter, "")
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleClose = () => {
        setShow(false);
        settype("");
        setcategory({});
    };

    function activeuser(item, isactive, event) {
        swal({
            title: `${isactive === 0
                ? "Xác nhận kích hoạt danh mục?"
                : "Bạn muốn xóa danh mục?"
                }`,
            text: `"${item.nameVi}"`,
            icon: `${isactive === 0 ? "success" : "error"}`,
            buttons: ['Đóng', 'Xóa'],
            dangerMode: isactive !== 0 ? true : false,
            successMode: isactive === 0 ? true : false,
        }).then((ok) => {
            if (ok) {
                axios.delete(`/category/destroy/${item.id}`)
                    .then((res) => {
                        getlistuser();
                        toast(`${res.data.data.mess}`, {
                            type: "success",
                            autoClose: 1000,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });
    }

    function getdetailuser(id) {
        axios.get(`/category/${id}`)
            .then((res) => {
                if (res.data.errCode == 0) {
                    setcategory(res.data.user);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const stringLastDate = (date) => ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    const toSlug = (str) => {
        str = str.toLowerCase(); // Chuyển hết sang chữ thường       
        // xóa dấu
        str = str
            .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
            .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
        str = str.replace(/[đĐ]/g, 'd');    // Thay ký tự đĐ
        str = str.replace(/([^0-9a-z-\s])/g, '');   // Xóa ký tự đặc biệt
        return str;
    }

    // Render
    const loading = () => {
        return <Card>
            <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                    <span className="sr-only">Loading...</span>
                </div>
            </Card.Body>
        </Card>

    }

    const RenderSelectedRows = () => {
        let num = selectedRows.length;
        if (num > 0 && alertShow === true) {
            return (
                <Alert
                    style={{
                        position: "absolute",
                        zIndex: "1",
                        left: "91%",
                    }}
                    variant="info mb-0"
                    onClose={() => setalertShow(false)}
                    dismissible
                >
                    {num} mục đã chọn.
                </Alert>
            );
        }
    };

    const DataTable = () => {
        const columns = [
            {
                name: "STT",
                selector: (row) => row.stt,
                // sortable: true,
            },
            {
                name: "Tên danh mục",
                selector: (row) => row.user_name,
                sortable: true,
            },
            {
                name: "Ngày cập nhật",
                selector: (row) => row.role_name,
                // sortable: true,
            },
            {
                name: "Phương thức",
                selector: (row) => row.setting,
                center: true,
            },
        ];

        const data = [];

        const selectedRows = (items) => {
            setselectedRows(items);
            setalertShow(true);
        };

        if (listcategory.length > 0) {
            listcategory.map((item, i) => {
                data.push({
                    stt: i + 1,
                    id: item.id,
                    user_name: item.nameVi,
                    role_name: moment(new Date(item.updatedAt)).format('YYYY-MM-DD'),
                    setting: (
                        <>

                            <Button variant="outline-warning me-2" size="sm"
                                // style={{color:'white'}}
                                title="Cập nhật danh mục"
                                onClick={() => {
                                    setid_user(item.id);
                                    getdetailuser(item.id);
                                    settype("CN");
                                    setShow(true);
                                }}
                            >
                                <i className="fa-solid fa-pencil"></i>
                            </Button>



                            <Button variant="outline-danger" size="sm" onClick={() => activeuser(item)} title="Xóa danh mục">
                                <i className="fa-solid fa-trash"></i>
                            </Button>

                        </>
                    ),
                });

                return item;
            });
        }

        return (
            <DataTables data={data} columns={columns} selectedRows={selectedRows} />
        );
    };

    function Formuser() {
        const [nameCategory, setnameCategory] = useState(category.nameVi);
        function convertToSlug(input) {
            // Xóa các ký tự đặc biệt, chuyển chữ hoa thành chữ thường và thay thế dấu cách bằng dấu gạch ngang
            return input
                .toLowerCase()
                .replace(/[^\w\s]/g, '') // Xóa các ký tự đặc biệt
                .replace(/\s+/g, '-')    // Thay thế dấu cách bằng dấu gạch ngang
                .trim();                 // Xóa các khoảng trắng ở đầu và cuối chuỗi
        }
        function createuser() {
            var arrCreate = {
                nameVi: nameCategory,
                slug: convertToSlug(nameCategory),
                // statusId: 'US1',
                // deleteId: 'D1'
            }

            axios.post(`/category/create`, arrCreate)
                .then((res) => {
                    if (res.data.errCode == 0) {
                        getlistuser();
                        handleClose();
                        setid_user("");
                        toast(`${res.data.message}`, {
                            type: "success",
                            autoClose: 1000,
                        });
                    } else {
                        toast(`${res.data.errMessages.nameVi}`, { type: "error", autoClose: 1000 });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        function updateuser() {
            var arrCreate = {
                nameVi: nameCategory,
                slug: convertToSlug(nameCategory),
                // statusId: 'US1',
                // deleteId: 'D1'
            }

            swal({
                title: "Bạn muốn cập nhật danh mục?",
                icon: 'warning',
                buttons: ['Đóng', 'Cập nhật'],
                successMode: true,
            }).then((ok) => {
                if (ok) {
                    axios.put(`/category/update/${id_user}`, arrCreate)
                        .then((res) => {
                            if (res.data.errCode == 0) {
                                getlistuser();
                                handleClose();
                                setid_user("");
                                toast(`${res.data.message}`, {
                                    type: "success",
                                    autoClose: 1000,
                                });
                            } else {
                                toast(`${res.data.message}`, { type: "error", autoClose: 1000 });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            });
        }

        const handleSubmit = (e) => {
            e.preventDefault();

            if (type === "TM") {
                createuser();
            } else {
                updateuser();
            }
        }


        return (
            <Modal show={show} size="md" onHide={handleClose} backdrop="static" keyboard={false} animation={false} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa-solid fa-chalkboard-user"></i>&nbsp;
                        {type === "TM" ? "Thêm danh mục" : "Chi tiết danh mục"}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <Modal.Body>


                        <Row>
                            <Col md={12}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={(e) => setnameCategory(e.target.value)}
                                        defaultValue={category.nameVi} placeholder="Danh mục" required />
                                    <Form.Label>Tên danh mục<i className="text-danger">*</i></Form.Label>
                                </Form.Floating>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button size="sm" variant="secondary" onClick={handleClose}>
                            <i className="fa-solid fa-times"></i> Đóng
                        </Button>
                        {type === "TM" ?
                            <Button size="sm" type="submit" variant="success">
                                <i className="fa-solid fa-check"></i> Lưu
                            </Button>
                            :
                            <Button size="sm" type="submit" variant="success">
                                <i className="fa-solid fa-pencil"></i> Cập nhật
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

    return (
        <Container fluid>

            <RenderSelectedRows />

            <Breadcrumb className="mb-2 mt-2 flex justify-content-start">
                <Breadcrumb.Item >Sản phẩm</Breadcrumb.Item>
                <Breadcrumb.Item active>Danh mục</Breadcrumb.Item>
            </Breadcrumb>

            <Card>
                <Card.Header>
                    <div className="d-block d-md-flex justify-content-between">
                        <Card.Title>
                            <i className="fas fa-list me-1" />
                            Danh mục sản phẩm
                        </Card.Title>

                        <div>
                            <Button type="button" className="mt-3 mt-md-0" variant="success" size="sm" onClick={() => {
                                settype("TM"); setShow(true);
                            }} title='Thêm danh mục'>
                                <i className="fa-solid fa-plus"></i> Thêm
                            </Button>
                        </div>

                    </div>
                </Card.Header>
                <Card.Body>
                    {/* {renderSearchFilter()} */}
                    {
                        // checkload ? loading() :
                        <DataTable />
                    }

                </Card.Body>
            </Card>
            {
                show ?
                    <Formuser /> : ""
            }
        </Container>
    );
}
