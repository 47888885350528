import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './sale.css'
// import Logo from '../../assets/client/images/logo.png'
import { faCartPlus, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons'
function Sale() {
	const navigate = useNavigate();

	// const sidenavToggle = () => {
	//     const navbarSupportedContent = document.getElementById('navbarSupportedContent');

	//     let testclass = navbarSupportedContent.className;

	//     if (testclass === 'navbar-collapse collapse show' && window.innerWidth < 992) {
	//         navbarSupportedContent.classList.remove('show');
	//     }
	// }

	return (
		<section className="cown-down">
			<div className="section-inner ">
				<div >
					<div className="row">
						<div className="col-lg-6 col-12 padding-right">
							<div className="image ">
								<img src="https://cdn.nhathuoclongchau.com.vn/unsafe/373x0/filters:quality(90)/https://cms-prod.s3-sgn09.fptcloud.com/00033518_vita_gummies_vitamin_d3_1000iu_120v_7608_6226_large_d82136ae38.jpg" alt="#" 
								height='200px'
								/>
							</div>
						</div>
						<div className="col-lg-6 col-12 padding-left">
							<div className="content">
								<div className="heading-block">
									<h3 className="small-title">Khuyến mãi hấp dẫn</h3>
									<h4 className="title">Chăm sóc sức khỏe toàn diện</h4>
									<p className="text">Bổ sung dĩnh dưỡng cho cả ngày dài, bảo vệ sức khỏe gia dình bạn. </p>
									<p className="price">199.000 ₫ <s>400.000 ₫</s></p>
									<div className="coming-time">
										<div className="clearfix" data-countdown="2021/02/30"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

	)
}

export default Sale;

