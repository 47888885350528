import React, { Component } from 'react';
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { error } from 'jquery';
import Check from "../../../components/admin/other/Check"
import FormatData from "../../../components/admin/FormatData";
// import Logo from "../../assets/admin/assets/img/logo_kafood_4.png"
// import Logo from "../../assets/admin/assets/img/logo-vplife-trong-suot.png"
import './navbar.css'
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listUser: [],
      user: {

      }, show: false,
      dataInput: {
        fullName: "",
        code: "",
        password: "",
      },
      listrole: [],
      showAlarm: false,
      ingredent_alarmlist: [],
      count: 0,
    }
  }
  async componentDidMount() {
    // this.getAllUser()
    // this.getlistrole()
    // this.getIngredent()
    this.getUser()

  }
  handleClose = () => {
    this.setState({ show: false, showAlarm: false })
  };
  getUser =()=>{

    axios.get(`/user/${localStorage.getItem('idLogin')}`).then((res) => {
      if (res.data.errCode==0) {
        this.setState({user:res.data.user})
      
        // this.setState({ listUser: res.data.data })
        // this.setState({ user: res.data.data.filter(e => e.id == Number(localStorage.getItem('auth_id_user')))[0] })
      }
    })
      .catch((error) => { console.log(error); });
  }
 

  sidenavToggle = (event) => {
    document.getElementById('sb-nav-fixed').classList.toggle('sb-sidenav-toggled');
  }
  dateNotTime(data) {
    let d = new Date(data * 1000);
    return d.getDate() + '/' + d.getMonth() + 1 + '/' + d.getFullYear();
  }
  logout = (event) => {
    swal({
      title: "Bạn muốn đăng xuất?",
      icon: "warning",
      buttons: ['Đóng', 'Đồng ý'],
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          localStorage.removeItem("auth_id_shool");
          localStorage.removeItem("auth_id_user");
          localStorage.removeItem("fullName");
          localStorage.removeItem("role_rank");
          localStorage.removeItem("role_id");
          localStorage.removeItem("role_name");
          localStorage.removeItem("permission");
          localStorage.removeItem("token_type");
          localStorage.removeItem("access_token");
          localStorage.removeItem("auth_phone");
          localStorage.removeItem("auth_account");
          localStorage.removeItem("vip");
          this.props.navHook('/login');

          toast('Đăng xuất thành công', { type: "success" });
        }
      });
  }
  updateuser = () => {
    swal({
      title: "Bạn muốn cập nhật tài khoản?",
      icon: 'warning',
      buttons: ['Đóng', 'Cập nhật'],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        var arrPost={
          userName:this.state.dataInput.code  || this.state.user.code,
          fullName:this.state.dataInput.fullName || this.state.user.fullName,
          password:this.state.dataInput.password 
        }
        axios.put(`user/update/${localStorage.getItem('idLogin')}`, arrPost)
        // axios.post(`/updateUser?id_user=${localStorage.getItem('auth_id_user')}&user_name=${this.state.dataInput?.user_name != undefined ? this.state.dataInput.user_name : this.state.user?.user_name}&user_account=${this.state.dataInput?.user_account != undefined ? this.state.dateInput.user_account : this.state.user?.user_account}&user_password=${this.state.dataInput?.password != undefined ? this.state.dataInput?.password : ""}&id_role=${this.state.dataInput?.id_role != undefined ? this.state.dateInput.id_role : this.state.user.id_role}&is_meal=${this.state.dateInput.is_meal != undefined ? this.state.dateInput.is_meal != undefined : this.state.user?.is_meal}`)
          .then((res) => {
            if (res.data.errCode==0) {
              this.handleClose();
              // this.getAllUser();
              localStorage.setItem('fullName',arrPost.fullName)
              {localStorage.getItem('fullName')}
              this.setState({ id_user: "" })
              toast(`${res.data.message}`, {
                type: "success",
                autoClose: 1000,
              });
            } else {
              toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  getIngredent = () => {
    axios.get('/viewInventoryAll').then((res) => {
      this.setState({
        ingredent_alarmlist:
          res.data.data.filter(item => (
            (item.expriry_date > new Date(new Date().setHours(0, 0, 0)) / 1000 &&
              new Date(new Date().setHours(0, 0, 0)) / 1000) + parseInt(item.ingredent_alarm) * 86400 >= item.expriry_date))
        , count: res.data.data.filter(item =>
        (
          (item.expriry_date > new Date(new Date().setHours(0, 0, 0)) / 1000 &&
            new Date(new Date().setHours(0, 0, 0)) / 1000) + parseInt(item.ingredent_alarm) * 86400 >= item.expriry_date))
          .length
      })
    })
      .catch((error) => { console.log(error) })
  }

  handleShow = () => {
    this.setState({ show: true })

  }
  handleShowAlarm = () => {
    this.getIngredent()
    if (this.state.count > 0)

      this.setState({ showAlarm: true })

  }
  handleUpdate = (event) => {
    let field = event.target.name;
    let value = event.target.value;
   
    let dataInput = { ...this.state.dataInput };
    dataInput[field] = value;
    this.setState({ dataInput: dataInput });

  }

  render() {
    return (
      // sb-topnav navbar navbar-expand navbar-dark bg-dark
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        <Modal show={this.state.show} size="md"

          onHide={this.handleClose}
          backdrop="static" keyboard={false} animation={false} >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa-solid fa-chalkboard-user"></i>&nbsp;
              Thông tin

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { }
            <Form>
              <Form.Floating className="mb-3">
                <Form.Control type="text"
                  name='fullName'
                  onChange={this.handleUpdate
                    // (e) => this.setState({ user_name: e.target.value })
                  }
                  defaultValue={this.state.user?.fullName} placeholder="Họ và tên" required />
                <Form.Label>Họ và tên<i className="text-danger">*</i></Form.Label>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control type="text" onChange={this.handleUpdate
                  // (e) => this.setState({ account: e.target.value })
                } 

                defaultValue={this.state.user?.code}
                  name='code' placeholder="Tài khoản" />
                <Form.Label>Tài khoản<i className="text-danger"></i></Form.Label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control type="password"
                  name='password'
                  onChange={this.handleUpdate
                    // (e) => this.setState({ password: e.target.value })
                  }
                  defaultValue={""}
                  placeholder="Mật khẩu" />
                <Form.Label>Mật khẩu<i className="text-danger"></i></Form.Label>
              </Form.Floating>

            
           
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            <Button type="submit" variant="primary" onClick={() => this.updateuser()}>
              <i className="fa-solid fa-pencil"></i> Cập nhật
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showAlarm}

          onHide={this.handleClose}
          backdrop="static" keyboard={false} animation={false} >
          <Modal.Header closeButton>
            <Modal.Title>

              Hàng sắp hết hạn

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered responsive>
              <thead>

                <tr>
                  <th style={{ width: '60px' }}> STT</th>
                  <th style={{ width: '170px' }}> Tên nguyên liệu</th>
                  <th style={{ width: '120px' }}> Lô</th>

                  <th style={{ width: '120px' }}> Ngày hết hạn</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "36px" }}></tr>
                {
                  this.state.ingredent_alarmlist?.map((item, index) => {
                    return (
                      <tr>
                        <td style={{ width: '60px' }}>
                          {index + 1}
                        </td>
                        <td style={{ width: '170px' }}> {item.ingredent_name}</td>
                        <td style={{ width: '120px' }} >{item.code_inventory}</td>
                        <td style={{ width: '120px' }} >
                          {
                            // this.dateNotTime(item.expriry_date)
                            new FormatData().date(item.expriry_date)
                            // new Date(item.expriry_date *1000)
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => {
              this.setState({ showAlarm: false })
              this.props.navHook("/admin/store")
            }}> Tới kho hàng</Button>
          </Modal.Footer>
        </Modal>
        <Link className="navbar-brand ps-3" to="/admin">
          {/* <img src={Logo} style={{ width: 69, height: 30}} alt="Kafood"/>  */}
          Ngọc Hằng Store
        </Link>

        {/* <Link className="navbar-brand ps-3 fw-bold" style={{ 'fontFamily': 'cursive' }} to="/admin">
          <span className='text-warning'>Ka</span><span className='text-info'>food</span>
        </Link> */}
        <Button variant='outline-info' size="sm" className="order-1 order-lg-0 me-2" id="sidebarToggle" onClick={this.sidenavToggle}>
          <i className="fas fa-bars"></i>
        </Button>

        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">

        </form>
        {/* {new Check().permission(['89']) ?
          <i className="fa-solid fa-bell fa-lg" style={{ color: this.state.count > 0 ? "#fff" : '#d5d5d5', paddingTop: '5px', cursor: this.state.count > 0 ? 'pointer' : null }} onClick={this.handleShowAlarm} >
            {this.state.count > 0 ? <sup >{this.state.count}</sup> : null} </i> : null} {" "} */}
        <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle text-white" id="navbarDropdown" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fas fa-user fa-fw"></i> {localStorage.getItem('fullName')}
            </Link>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li><Link className="dropdown-item" onClick={this.handleShow} ><i className="fa-solid fa-gear"> </i> Thông tin</Link></li>
              <li><hr className="dropdown-divider" /></li>
              <li><Link className="dropdown-item text-danger" onClick={this.logout}><i className="fa-solid fa-right-from-bracket"></i> Đăng xuất</Link></li>
            </ul>
          </li>
        </ul>

      </nav>

    );
  }
}


function myParams(Component) { //Back link AND use "params"
  return props => <Component navHook={useNavigate()} {...props} params={useParams()} />;
}

export default myParams(Navbar);

