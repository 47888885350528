import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../assets/client/css/responsive.css'
// import '../../assets/client/css/reset.css'
import '../../assets/client/css/style.css'
// import Logo from '../../assets/client/images/logo.png'
import { faCartPlus, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons'

import { Button, Col, Form, Row, Modal, Container } from 'react-bootstrap';
// import Navbar from '../../layouts/frontend/Navbar';
import Footer from './footer/Footer';
import Navbar from './navbarN/NavBar_N';
import NavbarMiddle from './navbarMiddle/Navbar_middle';
import TrendProduct from '../../components/frontend/trendProduct/TrendProduct';
import PageHero from './pageHero/PageHero';
import ProductItem from '../../components/frontend/product/ProductItem';
function HomePage() {
    const navigate = useNavigate();

    return (
        <>
            <NavbarMiddle />
            <Navbar />
            <Container>

                <section className="small-banner section">

                    <div >
                        <div className="row">
                            {/* <!-- Single Banner  --> */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="single-banner">
                                    <img src="https://via.placeholder.com/600x370" alt="#" />
                                    <div className="content">
                                        <p>Man's Collectons</p>
                                        <h3>Summer travel <br /> collection</h3>
                                        <a href="#">Discover Now</a>
                                    </div>
                                </div>
                            </div>
                         
                            <div className="col-lg-6 col-12">
                                <div className="single-banner tab-height">
                                    <img src="https://via.placeholder.com/600x370" alt="#" />
                                    <div className="content">
                                        <p>Flash Sale</p>
                                        <h3>Mid Season <br /> Up to <span>40%</span> Off</h3>
                                        <a href="#">Discover Now</a>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </section>
                <Row>
                <div style={{height:'350px', width:"250px"}}>
                <ProductItem />
                </div>
            </Row>
            </Container>
          

            {/* <Footer /> */}
        </>

    )
}

export default HomePage;

