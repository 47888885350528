import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavBar() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary  bg-dark">
      <Container>
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav " style={{background:'#f2f2f2'}} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to={'/'} >Trang chủ</Nav.Link>
            <Nav.Link as={NavLink} to={'/about'} >Giới thiệu</Nav.Link>
            <NavDropdown   title="Sản phẩm" id="basic-nav-dropdown">
              <NavDropdown.Item  as={NavLink} to={'/product'}>Thời trang trẻ</NavDropdown.Item>
              <NavDropdown.Item   as={NavLink} to={'/product'}>
              Thời trang trung niên
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;